import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { history } from 'helpers/history';
import {
  changePassword,
  setChangePasswordSuccess,
} from '../actions/authActions';
import { appName } from 'utils/axios';

import {
  Row,
  Col,
  TextField,
  Button,
  CustomSpinner,
} from '@mycentyr/centyr-ui';
import logoIcon from 'assets/images/WeShip1.png';
import img2 from 'assets/images/background/plain.png';

const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
};

const passwordPattern = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$/;
const schema = yup.object().shape({
  new_password: yup
    .string()
    .required('New Password is required')
    .matches(
      passwordPattern,
      'Password should contain atleast one number and one special character'
    )
    .oneOf(
      [yup.ref('confirm_password'), null],
      'Confirm Password does not match'
    )
    .min(6, 'Password must be Atleast 6 Charecters'),
});

const ChangePasswordForm = (props) => {
  document.title = 'Change Password ' + appName;

  const qs = queryString.parse(props.location.search);

  if (!qs.id || !qs.token) history.replace('/sign/in?resettoken=expired');

  const { register, handleSubmit, errors } = useForm({
    mode: 'onblur',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const postData = {
      token: qs.token,
      new_password: data.new_password,
    };
    props.changePassword(postData, qs.id);
  };

  const handleCancelChangePasswordClick = () => {
    props.setChangePasswordSuccess(null);
    history.push('sign/in');
  };
  return (
    <div className=''>
      {/*--------------------------------------------------------------------------------*/}
      {/*Register Cards*/}
      {/*--------------------------------------------------------------------------------*/}
      <div
        className='auth-wrapper d-flex no-block justify-content-center align-items-center'
        style={sidebarBackground}
      >
        <div className='auth-box on-sidebar'>
          <div id='loginform'>
            <div className='logo'>
              <a
                href='https://weshipafrica.com/'
                target='_blank'
                className='db'
              >
                <img
                  src={logoIcon}
                  height={35}
                  className='authLogo'
                  alt='logo'
                />
              </a>
              <h5 className='font-medium mb-3'>Change Password</h5>
              <span>Change Password and login with new password</span>
            </div>
            <Row>
              <Col xs='12'>
                {props.isChangePasswordPending && <CustomSpinner />}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row className='mt-3'>
                    <Col lg='12' xs='12'>
                      <TextField
                        label='New Password'
                        className='mb-3'
                        name='new_password'
                        id='new_password'
                        variant='outlined'
                        size='small'
                        fullWidth
                        type='password'
                        autoComplete='off'
                        error={errors.new_password?.message ? true : false}
                        helperText={errors.new_password?.message}
                        inputRef={register}
                      />
                      <p>
                        <strong>
                          Enter Minimum 6 to 14 Alpha Numeric Characters.
                          <br />
                          Minimum (1 Upper case, 1 Lower case, 1 Symbol ,1
                          Number)
                        </strong>
                      </p>
                    </Col>
                    <Col lg='12' xs='12'>
                      <TextField
                        label='Confirm Password'
                        className='mb-3'
                        name='confirm_password'
                        id='confirm_password'
                        variant='outlined'
                        size='small'
                        fullWidth
                        type='password'
                        autoComplete='off'
                        error={errors.confirm_password?.message ? true : false}
                        helperText={errors.confirm_password?.message}
                        inputRef={register}
                      />
                    </Col>
                    <Col xs='6' className='mb-3'>
                      <Button
                        type='submit'
                        color='primary'
                        block
                        disabled={props.isChangePasswordPending}
                      >
                        submit
                      </Button>
                    </Col>
                    <Col xs='6' className='mb-3'>
                      <Button
                        color='primary'
                        block
                        outline
                        onClick={handleCancelChangePasswordClick}
                      >
                        Sign in
                      </Button>
                    </Col>
                  </Row>
                </form>
                {props.changePasswordError && (
                  <div className={'alert alert-danger'}>
                    {props.changePasswordError}
                  </div>
                )}
                {props.changePasswordSuccess &&
                  handleCancelChangePasswordClick && (
                    <div className={'alert alert-success'}>
                      {props.changePasswordSuccess}
                    </div>
                  )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isChangePasswordPending: state.auth.isChangePasswordPending,
    changePasswordError: state.auth.changePasswordError,
    changePasswordSuccess: state.auth.changePasswordSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (postData, id) => {
      dispatch(changePassword(postData, id, 'auth'));
    },
    setChangePasswordSuccess: (data) => {
      dispatch(setChangePasswordSuccess(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
