import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { connect } from 'react-redux';

import { history } from 'helpers/history';
import { resetPassword } from '../actions/authActions';
import { appName } from 'utils/axios';

import {
  Row,
  Col,
  TextField,
  Button,
  CustomSpinner,
} from '@mycentyr/centyr-ui';

import logoIcon from 'assets/images/WeShip1.png';
import img2 from 'assets/images/background/plain.png';

const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
};

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Please enter valid email'),
});

const ResetPasswordForm = (props) => {
  document.title = 'Sign Up ' + appName;
  const { register, handleSubmit, errors } = useForm({
    mode: 'onblur',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    let email = data.email;
    props.resetPassword({ email });
  };

  const handleCancelResetPasswordClick = () => {
    history.push('sign/in');
  };
  return (
    <React.Fragment>
      <div className=''>
        {/*--------------------------------------------------------------------------------*/}
        {/*Login Cards*/}
        {/*--------------------------------------------------------------------------------*/}
        <div
          className='auth-wrapper d-flex no-block justify-content-center align-items-center'
          style={sidebarBackground}
        >
          <div className='auth-box on-sidebar'>
            <div id='loginform'>
              {props.resetPasswordError && (
                <div className={'alert alert-danger'}>
                  {props.resetPasswordError}
                </div>
              )}
              {props.resetPasswordSuccess && handleCancelResetPasswordClick && (
                <div className={'alert alert-success'}>
                  {props.resetPasswordSuccess}
                </div>
              )}
              <div className='logo'>
                <a
                  href='https://weshipafrica.com/'
                  target='_blank'
                  className='db'
                >
                  <img
                    src={logoIcon}
                    height={35}
                    className='authLogo'
                    alt='logo'
                  />
                </a>
                <h5 className='font-medium mb-3'>Recover Password</h5>
                <span>
                  Enter your Email and instructions will be sent to you!
                </span>
              </div>
              <Row>
                <Col xs='12'>
                  {props.isResetPasswordPending && <CustomSpinner />}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                      className='mt-3'
                      label='Email Address'
                      name='email'
                      id='reset_password_email'
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={errors.email?.message ? true : false}
                      helperText={errors.email?.message}
                      inputRef={register}
                    />
                    <Row className='mt-3'>
                      <Col xs='6' className='mb-3'>
                        <Button
                          type='submit'
                          color='primary'
                          block
                          disabled={props.isResetPasswordPending}
                        >
                          Reset Password
                        </Button>
                      </Col>
                      <Col xs='6' className='mb-3'>
                        <Button
                          color='primary'
                          block
                          outline
                          onClick={handleCancelResetPasswordClick}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isResetPasswordPending: state.auth.isResetPasswordPending,
    resetPasswordError: state.auth.resetPasswordError,
    resetPasswordSuccess: state.auth.resetPasswordSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (postData) => {
      dispatch(resetPassword(postData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
