import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Card,
  Box,
  Typography,
  IconButton,
  Divider,
  Button,
  Grid,
  CardMedia,
  CardContent,
  CardActions,
  Tooltip,
  ListItemIcon,
  MenuItem,
  Menu,
  ListItemText,
  Backdrop,
  CardActionArea,
} from "@material-ui/core";
import blueGrey from "@material-ui/core/colors/blueGrey";
import Description from "@material-ui/icons/Description";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import GetApp from "@material-ui/icons/GetApp";
import Lightbox from "react-awesome-lightbox";

import { bytesToSize } from "utils/fun";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    minHeight: "100%",
    padding: 3,
  },
  cardHeaderImage: { height: 140 },
  cardHeaderFile: {
    alignItems: "center",
    backgroundColor: blueGrey["50"],
    color: "#000000",
    display: "flex",
    height: 140,
    justifyContent: "center",
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const FileGrid = (props) => {
  const classes = useStyles();

  const moreRef = useRef(null);

  const [image, setImage] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleToggleBackdrop = () => {
    setOpenBackdrop(!openBackdrop);
  };

  const { files, moreButtons, donwloadAction, ...rest } = props;

  return (
    <Box className={classes.root} {...rest}>
      {image !== "" && (
        <Lightbox
          images={files.map((file) => {
            return { url: file.url, title: file.name };
          })}
          onClose={() => setImage("")}
        />
      )}
      <Grid container spacing={3}>
        {files.map((file, index) => (
          <Grid item key={index} md={6} sm={6} xs={12}>
            <Card className={classes.card}>
              {file.type.includes("image/") ? (
                <CardActionArea
                  onClick={() => {
                    // handleToggleBackdrop()
                    setImage(file.url);
                  }}
                >
                  <CardMedia
                    image={file.url}
                    className={classes.cardHeaderImage}
                  />
                </CardActionArea>
              ) : (
                <Box className={classes.cardHeaderFile}>
                  <Description fontSize="large" />
                </Box>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
      <Backdrop
        className={classes.backdrop}
        open={openBackdrop}
        onClick={handleCloseBackdrop}
      >
        <img width="50%" height="auto" src={image} />
      </Backdrop>
    </Box>
  );
};

export default FileGrid;
