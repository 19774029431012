import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import ThemeRoutes from 'routes/router';
import QuickLink from 'routes/quickLink';
import { TitleProvider, SplashScreen } from '@mycentyr/centyr-ui';
import Sidebar from './parts/sidebar';
import Header from './parts/header';
import Footer from './parts/footer';
import PerfectScrollbar from 'react-perfect-scrollbar';
import logoIcon from 'assets/images/weship-logo.png';

import { fetchUserDetail } from 'app/auth/actions/userDetailActions';
import { logout } from 'app/auth/actions/authActions';

const marginStyle = {
  marginLeft: window.innerWidth <= 991 ? 0 : '240px',
};

const mapStateToProps = (state) => ({
  ...state,
  authUserData: state.authUser.userData,
  isAuthenticated: state.auth.isAuthenticated,
  isLoginAsPending: state.auth.isLoginAsPending,
});

class Applayout extends React.Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      isOpen: false,
      width: window.innerWidth,
      userData: [],
      appName: process.env.REACT_APP_NAME,
    };

    this.props.history.listen((location, action) => {
      if (
        window.innerWidth < 991 &&
        document
          .getElementById('main-wrapper')
          .className.indexOf('show-sidebar') !== -1
      ) {
        document
          .getElementById('main-wrapper')
          .classList.toggle('show-sidebar');
      }
    });
  }
  /*--------------------------------------------------------------------------------*/
  /*Life Cycle Hook, Applies when loading or resizing App                           */
  /*--------------------------------------------------------------------------------*/
  componentDidMount() {
    this.props.fetchUserDetail();

    window.addEventListener('load', this.updateDimensions);
    window.addEventListener('resize', this.updateDimensions);
    document.addEventListener('click', this.handleClickOutside, true);

    if (this.props.qlinks) this.setState({ links: this.props.qlinks });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  handleClickOutside(event) {
    const domNode = ReactDOM.findDOMNode(this.wrapperRef);
    if (!domNode || !domNode.contains(event.target)) {
      if (
        window.innerWidth < 991 &&
        document
          .getElementById('main-wrapper')
          .className.indexOf('show-sidebar') !== -1
      ) {
        document
          .getElementById('main-wrapper')
          .classList.toggle('show-sidebar');
      }
    }
  }

  /*--------------------------------------------------------------------------------*/
  /*Function that handles sidebar, changes when resizing App                        */
  /*--------------------------------------------------------------------------------*/
  updateDimensions() {
    let element = document.getElementById('main-wrapper');
    this.setState({
      width: window.innerWidth,
    });

    switch (`iconbar`) {
      case 'full':
      case 'iconbar':
        if (this.state.width < 991) {
          element.setAttribute('data-sidebartype', 'mini-sidebar');
          element.classList.add('mini-sidebar');
        } else {
          element.setAttribute('data-sidebartype', `full`);
          element.classList.remove('mini-sidebar');
        }
        break;

      default:
    }
  }
  /*--------------------------------------------------------------------------------*/
  /*Life Cycle Hook                                                                 */
  /*--------------------------------------------------------------------------------*/
  componentWillUnmount() {
    window.removeEventListener('load', this.updateDimensions);
    window.removeEventListener('resize', this.updateDimensions);
    document.removeEventListener('click', this.handleClickOutside, true);
  }
  onScroll(e) {
    if (document.getElementsByClassName('page-header').length > 0) {
      if (e.scrollTop > 0)
        document
          .getElementsByClassName('page-header')[0]
          .classList.add('scrolled');
      else
        document
          .getElementsByClassName('page-header')[0]
          .classList.remove('scrolled');
    }
  }
  render() {
    /*--------------------------------------------------------------------------------*/
    /* Theme Setting && Layout Options wiil be Change From Here                       */
    /*--------------------------------------------------------------------------------*/
    if (this.props.authUserData.length === 0) return <SplashScreen />;
    else
      return (
        <div
          id='main-wrapper'
          dir={`ltr`}
          data-theme={`light`}
          data-layout={`vertical`}
          data-sidebartype={window.innerWidth >= 991 ? `full` : `mini-sidebar`}
          data-sidebar-position={`fixed`}
          data-header-position={`fixed`}
          data-boxed-layout={`full`}
          className={window.innerWidth >= 991 ? `` : `mini-sidebar`}
        >
          {/*--------------------------------------------------------------------------------*/}
          {/* Header                                                                         */}
          {/*--------------------------------------------------------------------------------*/}
          <Header {...this.props} qlinks={QuickLink} activeNavbarBg={`skin6`} />
          {/*--------------------------------------------------------------------------------*/}
          {/* Sidebar                                                                        */}
          {/*--------------------------------------------------------------------------------*/}
          <Sidebar
            ref={this.setWrapperRef}
            {...this.props}
            routes={ThemeRoutes}
            logo={logoIcon}
            appName={this.state.appName}
          />
          {/*--------------------------------------------------------------------------------*/}
          {/* Page Main-Content                                                              */}
          {/*--------------------------------------------------------------------------------*/}
          <div className='page-wrapper d-block' id='page-wrapper'>
            <PerfectScrollbar onScrollY={(e) => this.onScroll(e)}>
              <div
                className='page-content container-fluid'
                style={{ maxWidth: '1200px' }}
              >
                <Switch>
                  {ThemeRoutes.map((prop, key) => {
                    if (prop.child) {
                      return prop.child.map((prop2, key2) => {
                        if (prop2.collapse) {
                          return prop2.subchild.map((prop3, key3) => {
                            if (prop3.navlabel || !prop3.component) return null;
                            else
                              return (
                                <Route
                                  exact
                                  path={prop3.path}
                                  render={(props) => (
                                    <TitleProvider
                                      component={prop3.component}
                                      title={prop3.title || prop3.name}
                                      {...props}
                                    />
                                  )}
                                  key={key3}
                                />
                              );
                          });
                        } else if (prop2.navlabel || !prop2.component)
                          return null;
                        else {
                          return (
                            <Route
                              exact
                              path={prop2.path}
                              render={(props) => (
                                <TitleProvider
                                  component={prop2.component}
                                  title={prop2.title || prop2.name}
                                  {...props}
                                />
                              )}
                              key={key2}
                            />
                          );
                        }
                      });
                    } else if (prop.redirect) {
                      return (
                        <Redirect
                          exact
                          from={prop.path}
                          to={prop.pathTo}
                          key={key}
                        />
                      );
                    } else if (prop.name === 'Not Found') {
                      return (
                        <Route
                          render={(props) => (
                            <TitleProvider
                              component={prop.component}
                              title={prop.title || prop.name}
                              {...props}
                            />
                          )}
                          key={key}
                        />
                      );
                    } else {
                      return (
                        <Route
                          exact
                          path={prop.path}
                          render={(props) => (
                            <TitleProvider
                              component={prop.component}
                              title={prop.title || prop.name}
                              {...props}
                            />
                          )}
                          key={key}
                        />
                      );
                    }
                  })}
                </Switch>
              </div>
              <Footer />
            </PerfectScrollbar>
          </div>
        </div>
      );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserDetail: () => {
      dispatch(fetchUserDetail());
    },
    logout: () => {
      dispatch(logout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Applayout);
