import { CustomSnackbar } from '@mycentyr/centyr-ui';
import { instance } from 'utils/axios'

import {
    FETCH_COST_SUCCESS,
    FETCH_COST_PENDING,
    FETCH_COST_ERROR,
    FETCH_COST,
} from './types';

export const setCostBlank = () => dispatch => {
    dispatch({
        type: FETCH_COST,
        payload: null
    });
}

const setFetchCostSuccess = isFetchCostSuccess => dispatch => {
    dispatch({
        type: FETCH_COST_SUCCESS,
        payload: isFetchCostSuccess
    });
}

const setFetchCostPending = isFetchCostPending => dispatch => {
    dispatch({
        type: FETCH_COST_PENDING,
        payload: isFetchCostPending
    });
}

const setFetchCostError = fetchCostError => dispatch => {
    dispatch({
        type: FETCH_COST_ERROR,
        payload: fetchCostError
    });
}

export const calculateCost = (postData) => async dispatch => {
    dispatch(setCostBlank());
    dispatch(setFetchCostSuccess(false));
    dispatch(setFetchCostPending(true));
    instance.post('costcalculator', postData)
        .then(response => {
            dispatch({
                type: FETCH_COST,
                payload: response.data.data
            });
        })
        .catch(err => {
            if (err.response) {
                dispatch(setFetchCostError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        })
        .finally(() => {
            dispatch(setFetchCostPending(false));
        });

}