import { createSlice } from "@reduxjs/toolkit";
import { instance } from "utils/axios";
import { history } from "helpers/history";
import { CustomSnackbar } from "@mycentyr/centyr-ui";

const initialState = {
  transactions: [],
  noOfRecords: 0,
  isFetchTransactionsPending: false,
};

const slice = createSlice({
  name: "transactionData",
  initialState,
  reducers: {
    fetchTransactions(state, action) {
      state.transactions = action.payload.items;
      state.noOfRecords = action.payload.count;
    },
    setFetchTransactionsPending(state, action) {
      state.isFetchTransactionsPending = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const fetchTransactions = (
  pageNo = 1,
  perPage = 10,
  keyword = "",
  order = "",
  desc = false
) => async (dispatch) => {
  dispatch(slice.actions.setFetchTransactionsPending(true));
  dispatch(slice.actions.fetchTransactions({ items: [], count: -1 }));

  const response = await instance
    .post(
      `client/transactions/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${
        desc ? " desc" : ""
      }`,
      {}
    )
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setFetchTransactionsPending(false));
  if (response) {
    dispatch(slice.actions.fetchTransactions(response.data.data));
  }
};
export default slice;
