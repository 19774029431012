import React from 'react';
import notfound from 'assets/images/undraw_page_not_found_su7k.svg';

const NotFound = () => {

    return (
        <div style={{ textAlign: "center" }}>
            <h2>404: The page you are looking for isn’t here</h2>
            <h6>You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation</h6>
            <img
                style={{ width: "60%" }}
                alt="Not Found"
                src={notfound}
            />
        </div>
    );
};

export default NotFound;
