import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "redux/store";
import { fetchTransactions } from "../slices/transactionSlice";
import { history } from "helpers/history";
import TransactionSkeleeton from "./TransactionSkeleeton";

import MuiTable from "layouts/parts/mui-table";
import { Row, Col, Button } from "@mycentyr/centyr-ui";
import TransactionActionButtons from "./TransactionActionButtons";

const TransactionList = (props) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [rows, setRows] = useState([]);

  const { transactions, noOfRecords, isFetchTransactionsPending } = useSelector(
    (state) => state.transaction
  );

  const headCells = [
    {
      id: "package_name",
      numeric: false,
      disablePadding: false,
      label: "Package ID",
    },
    {
      id: "payment_gateway",
      numeric: false,
      disablePadding: false,
      label: "Pay By",
    },
    {
      id: "payment_order_id",
      numeric: false,
      disablePadding: false,
      label: "Payment Order ID",
    },
    {
      id: "amount",
      numeric: false,
      disablePadding: false,
      label: "Amount",
    },
    {
      id: "created_at",
      numeric: false,
      disablePadding: false,
      label: "Timestamp",
    },
    // { id: "action", numeric: true, disablePadding: false, label: "Action" },
  ];

  const createData = (
    id,
    package_name,
    payment_gateway,
    payment_order_id,
    amount,
    created_at
  ) => {
    return {
      id,
      package_name,
      payment_gateway,
      payment_order_id,
      amount,
      created_at,
    };
  };

  const onReload = (rowsPerPage, keyword) => {
    dispatch(fetchTransactions(1, rowsPerPage, keyword));
  };

  const handlePaginationChange = (pageNo, perPage, keyword) => {
    dispatch(fetchTransactions(pageNo, perPage, keyword));
  };

  const onSearch = (keyword, perPage) => {
    if (keyword.length > 2 || keyword.length === 0)
      dispatch(fetchTransactions(1, perPage, keyword));
  };

  useEffect(() => {
    let rowsLcl = [];
    transactions.map((pkg) =>
      rowsLcl.push(
        createData(
          pkg.package_id,
          pkg.package_name,
          pkg.payment_gateway,
          pkg.payment_order_id,
          `$ ${pkg.amount}`,
          pkg.created_at
        )
      )
    );
    setRows(rowsLcl);
  }, [transactions]);

  useEffect(() => {
    dispatch(fetchTransactions());
  }, []);

  return (
    <div>
      <div className="page-header">
        <Row>
          <Col className="page-title">
            <h1>{props.title}</h1>
          </Col>
          <Col className="text-right page-header-button">
            {props.authUserData.permissions &&
              props.authUserData.permissions.includes(
                "add.wsa.transactions"
              ) && (
                <Button
                  color="primary"
                  className="mb-3"
                  onClick={() => {
                    history.push("add");
                  }}
                >
                  Add Transaction
                </Button>
              )}
          </Col>
        </Row>
      </div>
      <div className="content-wrapper">
        <MuiTable
          headCells={headCells}
          rows={rows}
          onReload={onReload} // comment this to disable refresh option
          isFetchPending={isFetchTransactionsPending}
          // chooseColumn={false}   //=>to false display column option
          noOfRecords={noOfRecords}
          handlePaginationChange={handlePaginationChange}
          multipleDelete={
            props.authUserData.permissions &&
            props.authUserData.permissions.includes("delete.wsa.transactions")
              ? true
              : false
          }
          onSearch={onSearch}
          CustomSkeleton={TransactionSkeleeton}
          Actions={TransactionActionButtons}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUserData: state.authUser.userData,
  };
};

export default connect(mapStateToProps, null)(TransactionList);
