import { combineReducers } from "redux";
import { mainAuthReducer } from "app/auth/reducers/";

import { mainWSAReducer } from "app/wsa/reducer";

const reducers = combineReducers({
  ...mainAuthReducer,

  ...mainWSAReducer,
});

export default reducers;
