// Import Required Components
import NotFound from "layouts/parts/NotFound";

//import all routes files
import dashboardroutes from "./dashboardroutes";

var ThemeRoutes = [
  ...dashboardroutes,

  { path: "/", pathTo: "/dashboard", name: "Dashboard", redirect: true },
  {
    path: "/not-found",
    name: "Not Found",
    component: NotFound,
    routeonly: true,
  },
];
export default ThemeRoutes;
