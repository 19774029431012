import React from "react";
import {
  Nav,
  Navbar,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "@mycentyr/centyr-ui";
import MenuIcon from "@material-ui/icons/Menu";

import LocalStorageService from "helpers/LocalStorageService";

import { history } from "helpers/history";

//icons
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
class Header extends React.Component {
  constructor(props) {
    super(props);

    this.localStorageService = LocalStorageService.getService();
    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onQuickAddMouseEnter = this.onQuickAddMouseEnter.bind(this);
    this.onQuickAddMouseLeave = this.onQuickAddMouseLeave.bind(this);
    this.state = {
      isOpen: false,
      isQuickAddOpen: false,
      uid: "",
      links: [],
      showQuickAdd: false,
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.toggleBodyClass);
    this.toggleBodyClass();

    this.setState({ ...this.state, uid: this.localStorageService.getUid() });

    if (this.props.qlinks) this.setState({ links: this.props.qlinks });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.toggleBodyClass);
  }

  toggleBodyClass = () => {
    if (window.scrollY < 100) {
      document.body.classList.remove("scroll-body");
    } else {
      document.body.classList.add("scroll-body");
    }
  };

  // toggle = () => this.setState({ ...this.state, isOpen: !this.state.isOpen });

  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  showMobilemenu() {
    document.getElementById("main-wrapper").classList.toggle("show-sidebar");
  }

  toggle() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
      isQuickAddOpen: !prevState.isQuickAddOpen,
    }));
  }

  onMouseEnter() {
    this.setState({ isOpen: true });
  }

  onMouseLeave() {
    this.setState({ isOpen: false });
  }

  onQuickAddMouseEnter() {
    this.setState({ isQuickAddOpen: true });
  }

  onQuickAddMouseLeave() {
    this.setState({ isQuickAddOpen: false });
  }

  render() {
    const { userData } = this.props.authUser;

    if (userData)
      return (
        <header className="topbar navbarbg">
          <span className="nav-toggler d-block d-lg-none float-left">
            <MenuIcon
              style={{ cursor: "pointer" }}
              onClick={this.showMobilemenu}
            />
          </span>
          <Navbar
            className={
              "top-navbar " +
              (this.props.activeNavbarBg === "skin6"
                ? "navbar-light"
                : "navbar-dark")
            }
            expand="md"
          >
            <div className="navbarbg">
              <Nav className="ml-auto float-left" navbar>
                {/*--------------------------------------------------------------------------------*/}
                {/* Start Logout Dropdown                                                        */}
                {/*--------------------------------------------------------------------------------*/}
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    <span>Hi , {userData && userData.user_display_name}</span>
                    {userData && (
                      <img
                        src={userData.user_profile}
                        alt={userData && userData.user_display_name}
                        className="topbar-user-avatar"
                      />
                    )}
                  </DropdownToggle>

                  <DropdownMenu right className="mailbox">
                    <div className="message-center">
                      <span
                        className="message-item"
                        onClick={(e) => {
                          e.stopPropagation()
                          history.push('/client/profile')
                        }}
                      >
                        <PersonIcon />
                        <div className="mail-contnet">
                          <span className="sidebar-sublink-text">
                            My Profile
                          </span>
                        </div>
                      </span>

                      <span
                        className="message-item"
                        onClick={this.props.logout}
                      >
                        <ExitToAppIcon />
                        <div className="mail-contnet">
                          <span className="sidebar-sublink-text">
                            Logout{" "}
                            {this.localStorageService.getUid() &&
                              userData &&
                              " as (" + userData.user_display_name + ")"}
                          </span>
                        </div>
                      </span>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {/*--------------------------------------------------------------------------------*/}
                {/* End Logout Dropdown                                                          */}
                {/*--------------------------------------------------------------------------------*/}
              </Nav>
            </div>
          </Navbar>
        </header>
      );
  }
}

export default Header;
