import { mainPackageReducer } from "app/wsa/package/slices";
import { mainAddressReducer } from "app/wsa/address/slices";
import { mainCalculatorReducer } from "app/wsa/calculator/reducers";
import { mainUserReducer } from "app/wsa/user/reducers";
import { mainTransactionReducer } from "app/wsa/transaction/slices";

export const mainWSAReducer = {
  ...mainPackageReducer,
  ...mainAddressReducer,
  ...mainCalculatorReducer,
  ...mainTransactionReducer,
  ...mainUserReducer,
};
