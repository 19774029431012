import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'redux/store';
import {
  fetchPackage,
  changeStatus,
  getRates,
  completePayment,
} from '../slices/packageSlice';
import { fetchAllAddress } from 'app/wsa/address/slices/addressSlice';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isObjectEmpty } from 'utils/fun';
import Delete from '@material-ui/icons/Delete';

import {
  Row,
  Col,
  CustomSpinner,
  Card,
  CardTitle,
  CardBody,
  Divider,
  Box,
  FormControl,
  Button,
  CustomModal,
  InputLabel,
  Select,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
  Radio,
  FormLabel,
  Lightbox,
  Modal,
  ModalHeader,
  ModalBody,
} from '@mycentyr/centyr-ui';

import FileGrid from 'layouts/parts/FileGrid';

import { appName } from 'utils/axios';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import moment from 'moment';

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
} from '@material-ui/lab/';
import { styled } from '@material-ui/core/styles';
import PaypalButtons from './PaypalButtons';
import ConsolidatePackage from './ConsolidatePackage';
import AuthorizeNetForm from './AuthorizeNetForm';

const schemaRate = yup.object().shape({
  address_id: yup.string().required('Address is required'),
});
const initialValueRate = {
  address_id: '',
};

const MuiTimelineItem = styled(TimelineItem)({
  '&:before': {
    display: 'none',
  },
});

const ViewPackage = (props) => {
  const { id } = props.match.params;
  const [modal1, setModal1] = React.useState(false);
  const toggle1 = () => setModal1(!modal1);

  document.title = 'Manage Package' + appName;

  const [addressId, setAddressId] = React.useState('');
  const [rate, setRate] = React.useState(null);
  const [showPayment, setShowPayment] = React.useState(false);

  const [showship, setShowship] = React.useState(false);

  const [filesWithUrl, setFilesWithUrl] = useState([]);

  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);

  const [modal2, setModal2] = React.useState(false);
  const toggle2 = () => setModal2(!modal2);

  const onHold = () => {
    dispatch(changeStatus(id, 'hold', toggle));
  };

  const {
    handleSubmit: handleSubmitRate,
    errors: errorsRate,
    reset: resetRate,
    control: controlRate,
  } = useForm({
    mode: 'onblur',
    resolver: yupResolver(schemaRate),
    defaultValues: initialValueRate,
  });

  const onSubmitRate = (data) => {
    setRate(null);
    setShowPayment(false);
    setAddressId(data.address_id);
    const postData = {
      address_id: data.address_id,
    };
    dispatch(getRates(id, postData));
  };

  const dispatch = useDispatch();
  const {
    packageData,
    isFetchPackagePending,
    isAddUpdatePackagePending,
    isFetchRatePending,
    rates,
  } = useSelector((state) => state.package);

  const { addresses } = useSelector((state) => state.address);

  useEffect(() => {
    setModal2(false);
    setShowship(false);
    setRate(null);
    setShowPayment(false);
    setAddressId('');
    dispatch(fetchPackage(id));

    dispatch(fetchAllAddress());
  }, [id]);

  useEffect(() => {
    const packageImage = [];
    if (!isObjectEmpty(packageData)) {
      if (!isObjectEmpty(packageData.package_image)) {
        packageData.package_image?.map((imageData) => {
          packageImage.push({
            name: imageData.image_name,
            type: imageData.image_type,
            size: imageData.image_size,
            url: process.env.REACT_APP_API_URL + imageData.image_url,
          });
        });
        if (!isObjectEmpty(packageImage)) setFilesWithUrl(packageImage);
      }
    }
  }, [packageData]);

  const onSuccess = (data, details, gateway) => {
    const postData = {
      env: process.env.NODE_ENV === 'production' ? 'live' : 'sandbox',
      payment_order_id: data.orderID,
      data: rate,
      package_status: 'shipment-processing',
      is_paid: 1,
      gateway: gateway,
      address_id: addressId,
      description: JSON.stringify(details),
    };
    dispatch(completePayment(id, postData));
    setShowship(false);
  };

  if (isFetchPackagePending) return <CustomSpinner />;
  else
    return (
      <React.Fragment>
        <Modal size='xl' isOpen={modal1} toggle={toggle1}>
          <ModalHeader toggle={toggle1}>Consolidate Package</ModalHeader>
          <ModalBody>
            <ConsolidatePackage id={packageData.id} toggle={toggle1} />
          </ModalBody>
        </Modal>
        <div className='page-header'>
          <Row>
            <Col className='page-title'>
              <h1 className='page-title'>
                Manage Package ({packageData.package_id})
              </h1>
            </Col>
          </Row>
        </div>
        <div className='content-wrapper'>
          <Row>
            <Col lg='4' md='6' xs='12'>
              {packageData.consolidated_packages && (
                <Card>
                  <CardBody>
                    <Box
                      fontWeight='fontWeightBold'
                      fontSize='h6.fontSize'
                      m={1}
                    >
                      Packages to Consolidate
                    </Box>
                    <Divider className='mb-3' />
                    {packageData.consolidated_packages?.map((row, key) => (
                      <div className='mb-2'>
                        <b>Package Id:</b> {row.package_id}
                      </div>
                    ))}
                  </CardBody>
                </Card>
              )}
              <Card>
                <CardBody>
                  <Box fontWeight='fontWeightBold' fontSize='h6.fontSize' m={1}>
                    Package Information
                  </Box>
                  <Divider className='mb-3' />
                  <Box m={1}>
                    <div className='mb-2'>
                      <b>Package Id:</b> {packageData.package_id}
                    </div>
                    <div className='mb-2'>
                      <b>Commodity Type:</b>{' '}
                      {packageData.package_commodity_type}
                    </div>
                    <div className='mb-2'>
                      <b>Current Status:</b> {packageData.package_status}
                    </div>
                  </Box>
                  {packageData.package_type != 'consolidate' && (
                    <React.Fragment>
                      <Box
                        fontWeight='fontWeightBold'
                        fontSize='h6.fontSize'
                        m={1}
                      >
                        Inward Shipping Information
                      </Box>
                      <Divider className='mb-3' />
                      <Box m={1}>
                        <div className='mb-2'>
                          <b>Name of Sender:</b>{' '}
                          {packageData.package_sender_name}
                        </div>

                        <div className='mb-2'>
                          <b>Shipping Carrier:</b>{' '}
                          {packageData.package_inward_shipping_carrier}
                        </div>

                        <div className='mb-2'>
                          <b>Inward Tracking Number:</b>{' '}
                          {packageData.package_inward_tracking_number}
                        </div>

                        <div className='mb-2'>
                          <b>Received Time:</b>{' '}
                          {packageData.package_receive_time}
                        </div>
                      </Box>
                    </React.Fragment>
                  )}
                  <Box fontWeight='fontWeightBold' fontSize='h6.fontSize' m={1}>
                    Package Metrics
                  </Box>
                  <Divider className='mb-3' />
                  <Box m={1}>
                    <div className='mb-2'>
                      <b>Weight:</b>{' '}
                      {packageData.package_weight == 0
                        ? 'pending'
                        : packageData.package_weight +
                          ' ' +
                          packageData.package_weight_unit?.toUpperCase()}
                    </div>

                    <div className='mb-2'>
                      <b>Dimension(LxWxH):</b>{' '}
                      {packageData.package_weight == 0
                        ? 'pending'
                        : packageData.package_length +
                          'x' +
                          packageData.package_width +
                          'x' +
                          +packageData.package_height +
                          ' ' +
                          packageData.package_length_unit?.toUpperCase()}
                    </div>
                  </Box>
                  <Box fontWeight='fontWeightBold' fontSize='h6.fontSize' m={1}>
                    Package Images
                  </Box>
                  <Divider className='mb-3' />
                  <Box m={1}>
                    {!isObjectEmpty(filesWithUrl) && (
                      <React.Fragment>
                        <FileGrid
                          className='mb-3'
                          files={filesWithUrl}
                          moreButtons={[]}
                        />
                      </React.Fragment>
                    )}
                  </Box>
                </CardBody>
              </Card>
            </Col>
            <Col lg='8' md='6' xs='12'>
              <Timeline align='left'>
                {packageData.package_logs?.map((log, index) => (
                  <React.Fragment key={index}>
                    <MuiTimelineItem>
                      <TimelineSeparator>
                        <AccessTimeIcon />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <div
                          style={{
                            margin: '-5px',
                            paddingBottom: '13px',
                            fontSize: '1rem',
                          }}
                        >
                          {moment
                            .duration(moment(log.log_created_at).diff(moment()))
                            .humanize(true)}
                        </div>
                        <Card>
                          <CardTitle>{log.status}</CardTitle>
                          <CardBody>
                            <div>{log.message}</div>
                            <p className='text-right'>
                              <span className='text-right text-muted'>
                                {log.log_created_at}
                              </span>
                            </p>
                          </CardBody>
                        </Card>
                      </TimelineContent>
                    </MuiTimelineItem>
                  </React.Fragment>
                ))}

                {packageData &&
                  parseInt(packageData.is_paid) === 0 &&
                  packageData.package_weight != 0 && (
                    <MuiTimelineItem>
                      <TimelineSeparator>
                        <AccessTimeIcon />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <div
                          style={{
                            margin: '-5px',
                            paddingBottom: '13px',
                            fontSize: '1rem',
                          }}
                        >
                          Now
                        </div>
                        <Card>
                          <CardTitle>Your Action</CardTitle>
                          <CardBody className=''>
                            <p>What do you want to do with your package?</p>
                            <Row>
                              <Col lg={12}>
                                {packageData.package_status !== 'hold' && (
                                  <Button
                                    color='primary'
                                    outline
                                    onClick={toggle}
                                  >
                                    Hold
                                  </Button>
                                )}{' '}
                                {packageData.package_type != 'consolidate' && (
                                  <Button
                                    color='primary'
                                    outline
                                    onClick={toggle1}
                                  >
                                    Consolidate
                                  </Button>
                                )}{' '}
                                <Button
                                  color='primary'
                                  outline
                                  onClick={() => setShowship(true)}
                                >
                                  Ship Now
                                </Button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </TimelineContent>
                    </MuiTimelineItem>
                  )}
              </Timeline>

              {showship && (
                <Card>
                  <CardTitle>Ship Now</CardTitle>
                  <CardBody className=''>
                    {isFetchRatePending && <CustomSpinner />}
                    <form onSubmit={handleSubmitRate(onSubmitRate)}>
                      <Row>
                        <Col lg={6}>
                          <FormControl
                            variant='outlined'
                            size='small'
                            fullWidth
                            error={!!errorsRate.address_id}
                            className='mb-3'
                          >
                            <InputLabel>Address</InputLabel>
                            <Controller
                              as={
                                <Select native label='Address'>
                                  <option aria-label='None' value='' />
                                  {addresses.map((address, index) => (
                                    <option key={index} value={address.id}>
                                      {address.customer_address_name}
                                    </option>
                                  ))}
                                </Select>
                              }
                              name='address_id'
                              control={controlRate}
                            />
                            <FormHelperText>
                              {errorsRate.address_id?.message}
                            </FormHelperText>
                          </FormControl>
                        </Col>
                        <Col lg={12}>
                          <Button color='primary'>get rates</Button>
                        </Col>
                      </Row>
                    </form>
                    {rates && rates.length !== 0 && (
                      <Row className='mt-3'>
                        <Col lg={12}>
                          <FormControl component='fieldset'>
                            <FormLabel component='legend'>
                              Select Shipment
                            </FormLabel>
                            <RadioGroup
                              aria-label='shipping_detail'
                              name='shipping_detail'
                              onChange={(e) => {
                                setShowPayment(true);
                                setRate(e.target.value);
                              }}
                            >
                              {rates.map((rate, index) => (
                                <FormControlLabel
                                  key={index}
                                  value={JSON.stringify(rate)}
                                  control={<Radio />}
                                  label={`$ ${rate.rate} - ${rate.name}`}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Col>
                        <Col lg={12}>
                          {showPayment && (
                            <React.Fragment>
                              <Row>
                                <Col lg={12}>
                                  <PaypalButtons
                                    package={packageData}
                                    onSuccess={onSuccess}
                                    addressId={addressId}
                                    rate={JSON.parse(rate)}
                                  />
                                </Col>
                                <Col lg={12}>
                                  <Button
                                    className='mt-3'
                                    color='primary'
                                    outline
                                    size='lg'
                                    block
                                    onClick={toggle2}
                                  >
                                    Pay with Credit Card
                                  </Button>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </div>
        <CustomModal
          onModalAccept={onHold}
          onModalCancel={toggle}
          modal={modal}
          toggle={toggle}
          modalTitle='Hold Package'
          modalBody='Are you sure you want to hold?'
        />
        <Modal isOpen={modal2} toggle={toggle2}>
          <ModalHeader toggle={toggle2}>Pay with Credit Card</ModalHeader>
          <ModalBody>
            <AuthorizeNetForm
              id={id}
              toggle={toggle2}
              package={packageData}
              addressId={addressId}
              rate={JSON.parse(rate)}
            />
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
};

export default ViewPackage;
