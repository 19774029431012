import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "redux/store";
import { fetchAddresses, deleteAddresses } from "../slices/addressSlice";
import AddressActionButtons from "./AddressActionButtons";
import { history } from "helpers/history";
import AddressSkeleeton from "./AddressSkeleeton";
import MuiTable from "layouts/parts/mui-table";

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "@mycentyr/centyr-ui";
import OneAddress from "./OneAddress";

const AddressList = (props) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [rows, setRows] = useState([]);

  const { addresses, noOfRecords, isFetchAddressesPending } = useSelector(
    (state) => state.address
  );
  const { authUserData } = props;

  const headCells = [
    {
      id: "customer_address_name",
      numeric: false,
      disablePadding: false,
      label: "Address Name",
    },
    {
      id: "customer_address_country_name",
      numeric: false,
      disablePadding: false,
      label: "Country",
    },
    {
      id: "customer_address_state",
      numeric: false,
      disablePadding: false,
      label: "State",
    },
    {
      id: "customer_address_city",
      numeric: false,
      disablePadding: false,
      label: "City",
    },
    { id: "action", numeric: true, disablePadding: false, label: "Action" },
  ];

  const createData = (
    id,
    customer_address_name,
    customer_address_country_name,
    customer_address_state,
    customer_address_city
  ) => {
    return {
      id,
      customer_address_name,
      customer_address_country_name,
      customer_address_state,
      customer_address_city,
    };
  };

  const onReload = (rowsPerPage, keyword) => {
    dispatch(fetchAddresses(authUserData.id, 1, rowsPerPage, keyword));
  };

  const handlePaginationChange = (pageNo, perPage, keyword) => {
    dispatch(fetchAddresses(authUserData.id, pageNo, perPage, keyword));
  };

  const onMultiDelete = (postData, pageNo, perPage, keyword) => {
    dispatch(
      deleteAddresses(authUserData.id, postData, pageNo, perPage, keyword)
    );
  };

  const onSearch = (keyword, perPage) => {
    if (keyword.length > 2 || keyword.length === 0)
      dispatch(fetchAddresses(authUserData.id, 1, perPage, keyword));
  };

  useEffect(() => {
    let rowsLcl = [];
    addresses.map((address) =>
      rowsLcl.push(
        createData(
          address.id,
          address.customer_address_name,
          address.customer_address_country_name,
          address.customer_address_state,
          address.customer_address_city
        )
      )
    );
    setRows(rowsLcl);
  }, [addresses]);

  useEffect(() => {
    dispatch(fetchAddresses(authUserData.id));
  }, [authUserData]);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Address</ModalHeader>
        <ModalBody>
          <OneAddress id="add" toggle={toggle} />
        </ModalBody>
      </Modal>
      <div className="page-header">
        <Row>
          <Col className="page-title">
            <h1>{props.title}</h1>
          </Col>
          <Col className="text-right page-header-button">
            {props.authUserData.permissions &&
              props.authUserData.permissions.includes(
                "add.crm.customer.addresses"
              ) && (
                <Button color="primary" className="mb-3" onClick={toggle}>
                  Add Address
                </Button>
              )}
          </Col>
        </Row>
      </div>
      <div className="content-wrapper">
        <MuiTable
          headCells={headCells}
          rows={rows}
          onReload={onReload} // comment this to disable refresh option
          isFetchPending={isFetchAddressesPending}
          // chooseColumn={false}   //=>to false display column option
          noOfRecords={noOfRecords}
          handlePaginationChange={handlePaginationChange}
          multipleDelete={
            props.authUserData.permissions &&
            props.authUserData.permissions.includes(
              "delete.crm.customer.addresses"
            )
              ? true
              : false
          }
          onMultiDelete={onMultiDelete}
          onSearch={onSearch}
          CustomSkeleton={AddressSkeleeton}
          Actions={AddressActionButtons}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUserData: state.authUser.userData,
  };
};

export default connect(mapStateToProps, null)(AddressList);
