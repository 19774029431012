import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { connect } from 'react-redux';

import { fetchAllCountries } from 'app/wsa/address/slices/countrySlice';
import { calculateCost, setCostBlank } from '../actions/calculatorActions';

import {
  Card,
  CardBody,
  Row,
  Col,
  Select,
  CustomSpinner,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  FormControl,
  Button,
  TextField,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mycentyr/centyr-ui';
import { List, ListItem, ListItemText } from '@material-ui/core';

const schema = yup.object().shape({
  city: yup.string().required('City is required'),
  // zipcode: yup.string().required('Zip Code is required'),
  weight: yup.string().required('Weight is required'),
  // length: yup.string().required('Length is required'),
  // width: yup.string().required('Width is required'),
  // height: yup.string().required('Height is required'),
  //price: yup.string().required('Item Value is required'),
});

const Calculator = (props) => {
  const countryLabel = React.useRef(null);
  const [countryLabelWidth, setCountryLabelWidth] = React.useState(0);

  useEffect(() => {
    props.fetchAllCountries();
    props.setCostBlank();
    setCountryLabelWidth(countryLabel.current?.offsetWidth);
  }, []);

  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onblur',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const postData = {
      country: data.country,
      state: data.state,
      city: data.city,
      zipcode: data.zipcode,
      weight: data.weight,
      length: data.length,
      width: data.width,
      height: data.height,
      package_weight_unit: data.package_weight_unit,
      package_length_unit: data.package_length_unit,
    };
    props.calculateCost(postData);
  };

  return (
    <React.Fragment>
      <div className='page-header'>
        <Row>
          <Col className='page-title'>
            <h1 className='page-title'>{props.title}</h1>
          </Col>
        </Row>
      </div>
      <div className='content-wrapper'>
        <Row>
          <Col lg={{ size: 8, offset: 2 }} xs='12'>
            <Card>
              <CardBody className=''>
                {props.isFetchCostPending && <CustomSpinner />}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col lg='6' md='6' xs='12'>
                      <FormControl
                        variant='outlined'
                        size='small'
                        error={errors.country?.message ? true : false}
                        fullWidth
                        className='mb-3'
                      >
                        <InputLabel shrink ref={countryLabel} htmlFor='country'>
                          Country
                        </InputLabel>
                        <Select
                          native
                          inputRef={register}
                          input={
                            <OutlinedInput
                              notched
                              labelWidth={countryLabelWidth}
                              name='country'
                              id='country'
                            />
                          }
                        >
                          {props.countries &&
                            props.countries.map((country) => (
                              <option value={country.id} key={country.id}>
                                {country.country_name}
                              </option>
                            ))}
                        </Select>
                        <FormHelperText>
                          {errors.country?.message}
                        </FormHelperText>
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='6' md='6' xs='12'>
                      <TextField
                        label='City'
                        className='mb-3'
                        name='city'
                        id='city'
                        variant='outlined'
                        size='small'
                        fullWidth
                        error={errors.city?.message ? true : false}
                        helperText={errors.city?.message}
                        inputRef={register}
                      />
                    </Col>
                    <Col lg='6' md='6' xs='12'>
                      <TextField
                        label='Zip/Postal Code'
                        className='mb-3'
                        name='zipcode'
                        id='zipcode'
                        variant='outlined'
                        size='small'
                        fullWidth
                        error={errors.zipcode?.message ? true : false}
                        helperText={errors.zipcode?.message}
                        inputRef={register}
                      />
                    </Col>
                  </Row>
                  <Divider className='mb-4 mt-4' />
                  <Row>
                    <Col lg='6' md='6' xs='12'>
                      <TextField
                        label='Weight'
                        className='mb-3'
                        name='weight'
                        id='weight'
                        variant='outlined'
                        size='small'
                        fullWidth
                        error={errors.weight?.message ? true : false}
                        helperText={errors.weight?.message}
                        inputRef={register}
                      />
                    </Col>
                    <Col lg='6' md='6' xs='12'>
                      <RadioGroup
                        defaultValue='lb'
                        aria-label='package_weight_unit'
                        name='package_weight_unit'
                      >
                        <Row>
                          <Col lg='4' md='4' xs='12'>
                            <FormControlLabel
                              value='lb'
                              control={<Radio />}
                              label='LB'
                              inputRef={register}
                            />
                          </Col>
                          <Col lg='4' md='4' xs='12'>
                            <FormControlLabel
                              value='oz'
                              control={<Radio />}
                              label='OZ'
                              inputRef={register}
                            />
                          </Col>
                          <Col lg='4' md='4' xs='12'>
                            <FormControlLabel
                              value='kg'
                              control={<Radio />}
                              label='KG'
                              inputRef={register}
                            />
                          </Col>
                        </Row>
                      </RadioGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='6' md='6' xs='12'>
                      <TextField
                        label='Length'
                        className='mb-3'
                        name='length'
                        id='length'
                        variant='outlined'
                        size='small'
                        fullWidth
                        error={errors.length?.message ? true : false}
                        helperText={errors.length?.message}
                        inputRef={register}
                      />
                    </Col>
                    <Col lg='6' md='6' xs='12'>
                      <TextField
                        label='Height'
                        className='mb-3'
                        name='height'
                        id='height'
                        variant='outlined'
                        size='small'
                        fullWidth
                        error={errors.height?.message ? true : false}
                        helperText={errors.height?.message}
                        inputRef={register}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='6' md='6' xs='12'>
                      <TextField
                        label='Width'
                        className='mb-3'
                        name='width'
                        id='width'
                        variant='outlined'
                        size='small'
                        fullWidth
                        error={errors.width?.message ? true : false}
                        helperText={errors.width?.message}
                        inputRef={register}
                      />
                    </Col>
                    <Col lg='6' md='6' xs='12'>
                      <RadioGroup
                        defaultValue='in'
                        aria-label='package_length_unit'
                        name='package_length_unit'
                      >
                        <Row>
                          <Col lg='6' md='6' xs='12'>
                            <FormControlLabel
                              value='in'
                              control={<Radio />}
                              label='IN'
                              inputRef={register}
                            />
                          </Col>
                          <Col lg='6' md='6' xs='12'>
                            <FormControlLabel
                              value='cm'
                              control={<Radio />}
                              label='CM'
                              inputRef={register}
                            />
                          </Col>
                        </Row>
                      </RadioGroup>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col lg="6" md="6" xs="12">
                                            <TextField
                                                label="Value (USD)"
                                                className="mb-3"
                                                name="price"
                                                id="price"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                error={errors.price?.message ? true : false}
                                                helperText={errors.price?.message}
                                                inputRef={register}
                                            />
                                        </Col> */}
                  </Row>
                  <Row>
                    <Col lg='12' xs='12'>
                      <Button
                        className='pull-right'
                        type='submit'
                        color='primary'
                      >
                        Get Shipping Rates
                      </Button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {props.costs && (
          <Row>
            <Col lg={{ size: 8, offset: 2 }} xs='12'>
              <Card>
                <CardBody className=''>
                  <List>
                    {props.costs &&
                      props.costs.map((cost, key) => {
                        return (
                          <React.Fragment key={key}>
                            <ListItem alignItems='flex-start' key={key}>
                              <ListItemText
                                primary={'$' + cost.rate + ' - ' + cost.name}
                                secondary={cost.description}
                              />
                            </ListItem>
                            <Divider component='li' />
                          </React.Fragment>
                        );
                      })}
                  </List>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    countries: state.country.countries,
    costs: state.cost.costs,
    isFetchCostPending: state.cost.isFetchCostPending,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllCountries: () => {
      dispatch(fetchAllCountries());
    },
    setCostBlank: () => {
      dispatch(setCostBlank());
    },
    calculateCost: (postData) => {
      dispatch(calculateCost(postData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calculator);
