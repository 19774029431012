import { createSlice } from "@reduxjs/toolkit";
import { instance } from "utils/axios";
import { history } from "helpers/history";
import { CustomSnackbar } from "@mycentyr/centyr-ui";

const initialState = {
  packages: [],
  rates: [],
  noOfRecords: 0,
  packageData: {},
  isFetchPackagesPending: false,
  isFetchPackagePending: false,
  isAddUpdatePackagePending: false,
  isFetchRatePending: false,
};

const slice = createSlice({
  name: "packageData",
  initialState,
  reducers: {
    fetchPackages(state, action) {
      state.packages = action.payload.items;
      state.noOfRecords = action.payload.count;
    },
    fetchRates(state, action) {
      state.rates = action.payload;
    },
    fetchPackage(state, action) {
      state.packageData = action.payload;
    },
    setFetchPackagesPending(state, action) {
      state.isFetchPackagesPending = action.payload;
    },
    setFetchPackagePending(state, action) {
      state.isFetchPackagePending = action.payload;
    },
    setAddUpdatePackagesPending(state, action) {
      state.isAddUpdatePackagePending = action.payload;
    },
    setFetchRatePending(state, action) {
      state.isFetchRatePending = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const fetchPackages =
  (
    pageNo = 1,
    perPage = 10,
    keyword = "",
    datafor = "",
    status = "all",
    order = "",
    desc = false
  ) =>
  async (dispatch) => {
    dispatch(slice.actions.setFetchPackagesPending(true));
    dispatch(slice.actions.fetchPackages({ items: [], count: -1 }));

    const response = await instance
      .post(
        `packages/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${
          desc ? " desc" : ""
        }&status=${status}&for=${datafor}`,
        {}
      )
      .catch((err) => {
        if (err.response) {
          CustomSnackbar("error", err.response.data.data);
        }
      });
    dispatch(slice.actions.setFetchPackagesPending(false));
    if (response) {
      dispatch(slice.actions.fetchPackages(response.data.data));
    }
  };

export const fetchPackage = (id) => async (dispatch) => {
  dispatch(slice.actions.setFetchPackagePending(true));
  dispatch(slice.actions.fetchRates([]));

  const response = await instance
    .post(`packages/get/${id}`, {})
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setFetchPackagePending(false));
  if (response) {
    dispatch(slice.actions.fetchPackage(response.data.data));
  }
};

export const addPackage = (postData) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdatePackagesPending(true));

  const response = await instance
    .post("packages/create", postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdatePackagesPending(false));
  if (response) {
    CustomSnackbar("success", "Package added successfully");
    history.replace("/packages/list");
  }
};

export const updatePackage = (id, postData) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdatePackagesPending(true));

  const response = await instance
    .post(`packages/update/${id}`, postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdatePackagesPending(false));
  if (response) {
    CustomSnackbar("success", "Package updated successfully");
  }
};

export const consolidatePackage = (postData, toggle) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdatePackagesPending(true));

  const response = await instance
    .post("packages/consolidate", postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdatePackagesPending(false));
  if (response) {
    toggle();
    CustomSnackbar("success", "Package Consolidated successfully");
    history.replace(`/client/packages/${response.data.data.id}`);
  }
};
export const changeStatus = (id, status, toggle) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdatePackagesPending(true));
  let postData = {
    id: id,
    status: status,
  };
  const response = await instance
    .post("packages/change_status", postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdatePackagesPending(false));
  if (response) {
    CustomSnackbar("success", "Package Status changed successfully");
    toggle();
    dispatch(fetchPackage(id));
  }
};
export const completePayment = (id, postData) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdatePackagesPending(true));

  const response = await instance
    .post(`packages/change_payment_status/${id}`, postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdatePackagesPending(false));
  if (response) {
    CustomSnackbar("success", "Package Status changed successfully");
    dispatch(fetchPackage(id));
  }
};

export const payWithAuthorize = (id, postData, toggle) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdatePackagesPending(true));

  const response = await instance
    .post(`packages/pay_with_authorize/${id}`, postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdatePackagesPending(false));
  if (response) {
    CustomSnackbar("success", "Package Status changed successfully");
    toggle();
    dispatch(fetchPackage(id));
  }
};

export const deletePackages =
  (postData, pageNo, perPage, keyword = "") =>
  async (dispatch) => {
    const response = await instance
      .post("packages/delete", postData)
      .catch((err) => {
        if (err.response) {
          CustomSnackbar("error", err.response.data.data);
        }
      });
    if (response) {
      CustomSnackbar("success", "Package deleted Successfully");
      dispatch(fetchPackages(pageNo, perPage, keyword));
    }
  };

export const getRates = (id, postData) => async (dispatch) => {
  dispatch(slice.actions.setFetchRatePending(true));
  dispatch(slice.actions.fetchRates([]));

  const response = await instance
    .post(`packages/getrates/${id}`, postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setFetchRatePending(false));
  if (response) {
    dispatch(slice.actions.fetchRates(response.data.data));
  }
};

export const getTrack = (id) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdatePackagesPending(true));

  const response = await instance.post(`/packages/track/${id}`).catch((err) => {
    if (err.response) {
      CustomSnackbar("error", err.response.data.data);
    }
  });
  dispatch(slice.actions.setAddUpdatePackagesPending(false));
  if (response) {
    console.log(response.data.data.link);
    window.open(response.data.data.link, "_blank");
    // let labelData = response.data.data.labels[0].labelData;
    // var a = document.createElement("a");
    // a.href = "data:image/png;base64," + labelData;
    // a.download = id + "-label.png";
    // a.click();
  }
};

export default slice;
