import {
    FETCH_COST_SUCCESS,
    FETCH_COST_PENDING,
    FETCH_COST_ERROR,
    FETCH_COST,
} from '../actions/types';

const initialState = {
    isFetchCostSuccess: false,
    isFetchCostPending: false,
    fetchCostError: null,
    costs: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        case FETCH_COST:
            return {
                ...state,
                costs: action.payload
            };

        case FETCH_COST_SUCCESS:
            return {
                ...state,
                isFetchCostSuccess: action.payload
            };

        case FETCH_COST_PENDING:
            return {
                ...state,
                isFetchCostPending: action.payload
            };

        case FETCH_COST_ERROR:
            return {
                ...state,
                fetchCostError: action.payload
            };

        default:
            return state;
    }
}