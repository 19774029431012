import { instance, authinstance } from "utils/axios";
import {
  EMAIL_VERIFIED,
  AUTH_STATE_CHANGED,
  REGISTER_PENDING,
  REGISTER_ERROR,
  LOGIN_PENDING,
  LOGINAS_PENDING,
  LOGIN_ERROR,
  RESET_PASSWORD_PENDING,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_PENDING,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  FETCH_USER_DETAIL,
} from "./types";

import { fetchUserDetail } from "./userDetailActions";

import { history } from "helpers/history";
import firebase from "lib/firebase";
import LocalStorageService from "helpers/LocalStorageService";
import { CustomSnackbar } from "@mycentyr/centyr-ui";

const localStorageService = LocalStorageService.getService();

const setEmailNotVerified = (isEmailNotVerified) => (dispatch) => {
  dispatch({
    type: EMAIL_VERIFIED,
    payload: isEmailNotVerified,
  });
};

const setLoginPending = (isLoginPending) => (dispatch) => {
  dispatch({
    type: LOGIN_PENDING,
    payload: isLoginPending,
  });
};
export const setLoginAsPending = (isLoginAsPending) => (dispatch) => {
  dispatch({
    type: LOGINAS_PENDING,
    payload: isLoginAsPending,
  });
};

const setLoginError = (loginError) => (dispatch) => {
  dispatch({
    type: LOGIN_ERROR,
    payload: loginError,
  });
};

const setRegisterPending = (isRegisterPending) => (dispatch) => {
  dispatch({
    type: REGISTER_PENDING,
    payload: isRegisterPending,
  });
};

const setRegisterError = (registerError) => (dispatch) => {
  dispatch({
    type: REGISTER_ERROR,
    payload: registerError,
  });
};

const setResetPasswordPending = (isResetPasswordPending) => (dispatch) => {
  dispatch({
    type: RESET_PASSWORD_PENDING,
    payload: isResetPasswordPending,
  });
};

const setResetPasswordError = (resetPasswordError) => (dispatch) => {
  dispatch({
    type: RESET_PASSWORD_ERROR,
    payload: resetPasswordError,
  });
};
const setResetPasswordSuccess = (resetPasswordSuccess) => (dispatch) => {
  dispatch({
    type: RESET_PASSWORD_SUCCESS,
    payload: resetPasswordSuccess,
  });
};

const setChangePasswordPending = (isChangePasswordPending) => (dispatch) => {
  dispatch({
    type: CHANGE_PASSWORD_PENDING,
    payload: isChangePasswordPending,
  });
};

const setChangePasswordError = (changePasswordError) => (dispatch) => {
  dispatch({
    type: CHANGE_PASSWORD_ERROR,
    payload: changePasswordError,
  });
};
export const setChangePasswordSuccess =
  (changePasswordSuccess) => (dispatch) => {
    dispatch({
      type: CHANGE_PASSWORD_SUCCESS,
      payload: changePasswordSuccess,
    });
  };

export const setAuthState = (isAuthenticated, user) => (dispatch) => {
  dispatch({
    type: AUTH_STATE_CHANGED,
    payload: {
      isAuthenticated,
      user,
    },
  });
};

export const register = (postData, redirectTo) => async (dispatch) => {
  dispatch(setRegisterPending(true));

  authinstance
    .post(`auth/register`, postData)
    .then((response) => {
      CustomSnackbar("success", "User Successfully Registered");
      history.push(redirectTo);
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setRegisterError(err.response.data.data));
        CustomSnackbar("error", err.response.data.data);
      }
    })
    .finally(() => {
      dispatch(setRegisterPending(false));
      dispatch(setRegisterError(null));
    });
};

export const login =
  (postData, from = "/") =>
  async (dispatch) => {
    dispatch(setEmailNotVerified(false));
    dispatch(setLoginPending(true));

    postData = {
      ...postData,
      grant_type: "password",
    };

    const success = await firebase
      .auth()
      .signInWithEmailAndPassword(postData.email, postData.password)
      .catch(function (error) {
        if (
          error.code === "auth/wrong-password" ||
          error.code === "auth/user-not-found"
        ) {
          dispatch(setLoginError("Email or password is incorrect"));
        } else {
          dispatch(
            setLoginError("Something went wrong. Please try again later.")
          );
        }
      });
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (user.emailVerified) {
          dispatch(
            setAuthState(true, {
              id: user.uid,
              avatar: user.photoURL,
              email: user.email,
              name: user.displayName || user.email,
            })
          );
        } else {
          dispatch(setEmailNotVerified(true));
          dispatch(setLoginError("Email Address not Verified"));
        }
      } else {
        dispatch(setAuthState(false, null));
      }
    });

    dispatch(setLoginPending(false));
    success && dispatch(setLoginError(null)) && history.push(from);
  };

export const loginAs = (postData) => async (dispatch) => {
  dispatch(setLoginAsPending(true));

  dispatch({
    type: FETCH_USER_DETAIL,
    payload: [],
  });

  postData = {
    ...postData,
  };
  localStorageService.setUid(firebase.auth().currentUser.uid);

  await instance
    .post(`users/generateCustomToken`, postData)
    .then(async (response) => {
      const success = await firebase
        .auth()
        .signInWithCustomToken(response.data.data)
        .catch(function (error) {
          dispatch(
            setLoginError("Something went wrong. Please try again later.")
          );
        });
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          dispatch(fetchUserDetail());
          dispatch(
            setAuthState(true, {
              id: user.uid,
              avatar: user.photoURL,
              email: user.email,
              name: user.displayName || user.email,
            })
          );
        } else {
          dispatch(setAuthState(false, null));
        }
      });

      dispatch(setLoginAsPending(false));
      success && dispatch(setLoginError(null));
      success && history.push("/");
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setLoginError(err.response.data.data));
        CustomSnackbar("error", err.response.data.data);
      }
    });
};

export const resetPassword = (postData) => async (dispatch) => {
  dispatch(setResetPasswordPending(true));

  authinstance
    .post(`auth/reset_password`, postData)
    .then((response) => {
      dispatch(setResetPasswordPending(false));
      dispatch(setResetPasswordError(null));
      CustomSnackbar("success", response.data.data);
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setResetPasswordPending(false));
        dispatch(setResetPasswordError(err.response.data.data));
        CustomSnackbar("error", err.response.data.data);
      }
    })
    .finally(() => {
      dispatch(setResetPasswordPending(false));
      dispatch(setResetPasswordPending(false));
      dispatch(setResetPasswordError(null));
    });
};

export const changePassword =
  (postData, id, from = "user") =>
  async (dispatch) => {
    dispatch(setChangePasswordPending(true));
    if (from == "auth") {
      postData = {
        ...postData,
        id: id,
      };
      authinstance
        .post(`auth/setPassword`, postData)
        .then((response) => {
          dispatch(setChangePasswordPending(false));
          dispatch(setChangePasswordError(null));
          dispatch(setChangePasswordSuccess(response.data.data));
          CustomSnackbar("success", response.data.data);
          history.push('sign/in');
        })
        .catch((err) => {
          dispatch(setChangePasswordPending(false));
          dispatch(setChangePasswordError(err.response.data.data));
          CustomSnackbar("error", err.response.data.data);
        });
    } else
      instance
        .post(`users/change_password/${id}`, postData)
        .then((response) => {
          dispatch(setChangePasswordPending(false));
          dispatch(setChangePasswordError(null));
          CustomSnackbar("success", response.data.data);
        })
        .catch((err) => {
          dispatch(setChangePasswordPending(false));
          dispatch(setChangePasswordError(err.response.data.data));
          CustomSnackbar("error", err.response.data.data);
        });
  };

export const sendVerificationEmail = (email) => async (dispatch) => {
  instance
    .post(`auth/reverify_email`, { email: email })
    .then((response) => {
      CustomSnackbar("success", response.data.data);
    })
    .catch((err) => {
      CustomSnackbar("error", err.response.data.data);
    });
};

export const logout = () => async (dispatch) => {
  let uid = localStorageService.getUid();

  if (uid) {
    dispatch({
      type: FETCH_USER_DETAIL,
      payload: [],
    });
    dispatch(setLoginAsPending(true));
    instance
      .post(`users/generateCustomToken`, { uid: uid })
      .then(async (response) => {
        const success = await firebase
          .auth()
          .signInWithCustomToken(response.data.data)
          .catch(function (error) {
            dispatch(
              setLoginError("Something went wrong. Please try again later.")
            );
          });
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            dispatch(fetchUserDetail());
            dispatch(
              setAuthState(true, {
                id: user.uid,
                avatar: user.photoURL,
                email: user.email,
                name: user.displayName || user.email,
              })
            );
          } else {
            dispatch(setAuthState(false, null));
          }
        });
        dispatch(setLoginAsPending(false));
        success && dispatch(setLoginError(null));
        success && localStorageService.clearUid();
        success && history.push("/");
      })
      .catch((err) => {
        if (err.response) {
          dispatch(setLoginError(err.response.data.data));
          CustomSnackbar("error", err.response.data.data);
        }
      });
  } else {
    await firebase.auth().signOut();
    history.push("/");
  }
};
