import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'redux/store';

import { payWithAuthorize } from '../slices/packageSlice';

import cvvImage from 'assets/images/cvv.png';

import {
  Row,
  Col,
  CustomSpinner,
  TextField,
  Button,
  OutlinedInput,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from '@mycentyr/centyr-ui';
import moment from 'moment';

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const schema = yup.object().shape({
  card_number: yup.string().required('Card Number is required'),
  cvv: yup.string().required('CVV is required'),
});

const initialValue = {
  card_number: '',
  cvv: '',
  month: moment().month() + 1,
};

const AuthorizeNetForm = (props) => {
  const [years, setYears] = React.useState([]);
  const monthLabel = React.useRef(null);
  const [monthLabelWidth, setMonthLabelWidth] = React.useState(0);

  const yearLabel = React.useRef(null);
  const [yearLabelWidth, setYearLabelWidth] = React.useState(0);

  useEffect(() => {
    setMonthLabelWidth(monthLabel.current?.offsetWidth);
    setYearLabelWidth(yearLabel.current?.offsetWidth);
  }, []);

  useEffect(() => {
    let yearArray = [];
    for (let i = 0; i <= 20; i++) {
      yearArray.push(moment().add(i, 'years').year());
    }
    setYears(yearArray);
  }, []);

  const handleKeypress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const dispatch = useDispatch();
  const { isAddUpdatePackagePending } = useSelector((state) => state.package);

  const { register, handleSubmit, errors, reset, control } = useForm({
    mode: 'onblur',
    resolver: yupResolver(schema),
    defaultValues: initialValue,
  });

  const onSubmit = (data) => {
    const postData = {
      env: process.env.NODE_ENV === 'production' ? 'live' : 'sandbox',
      package_status: 'shipment-processing',
      data: JSON.stringify(props.rate),
      gateway: 'credit-card',
      address_id: props.addressId,
      card_number: data.card_number,
      month: data.month,
      year: data.year,
      cvv: data.cvv,
      amount: props.rate.rate,
    };
    dispatch(payWithAuthorize(props.id, postData, props.toggle));
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg='12'>
            <TextField
              className='mb-3'
              name='card_number'
              label='Card Number'
              id='card_number'
              variant='outlined'
              size='small'
              fullWidth
              onKeyPress={handleKeypress}
              error={errors.card_number?.message ? true : false}
              helperText={errors.card_number?.message}
              inputRef={register}
            />
          </Col>
          <Col lg='6'>
            <FormControl
              variant='outlined'
              size='small'
              error={errors.month?.message ? true : false}
              fullWidth
              className='mb-3'
            >
              <InputLabel shrink ref={monthLabel} htmlFor='month'>
                Month
              </InputLabel>
              <Select
                native
                inputRef={register}
                input={
                  <OutlinedInput
                    notched
                    labelWidth={monthLabelWidth}
                    name='month'
                    id='month'
                  />
                }
              >
                {months.map((x, i) => (
                  <option value={x} key={i}>
                    {x}
                  </option>
                ))}
              </Select>
              <FormHelperText>{errors.month?.message}</FormHelperText>
            </FormControl>
          </Col>
          <Col lg='6'>
            <FormControl
              variant='outlined'
              size='small'
              error={errors.year?.message ? true : false}
              fullWidth
              className='mb-3'
            >
              <InputLabel shrink ref={yearLabel} htmlFor='year'>
                Year
              </InputLabel>
              <Select
                native
                inputRef={register}
                input={
                  <OutlinedInput
                    notched
                    labelWidth={yearLabelWidth}
                    name='year'
                    id='year'
                  />
                }
              >
                {years.map((x, i) => (
                  <option value={x} key={i}>
                    {x}
                  </option>
                ))}
              </Select>
              <FormHelperText>{errors.year?.message}</FormHelperText>
            </FormControl>
          </Col>
          <Col lg='6'>
            <TextField
              className='mb-3'
              name='cvv'
              label='CVV'
              id='cvv'
              variant='outlined'
              size='small'
              fullWidth
              onKeyPress={handleKeypress}
              error={errors.cvv?.message ? true : false}
              helperText={errors.cvv?.message}
              inputRef={register}
            />
          </Col>
          <Col lg={6}>
            <img src={cvvImage} height={45} alt='CVV' />
          </Col>
        </Row>

        <Row>
          <Col lg='12' className='text-right'>
            <Button type='submit' color='primary'>
              Pay Now
            </Button>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};

export default AuthorizeNetForm;
