import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import FileCopy from '@material-ui/icons/FileCopy';

var QuickLink = [
    {
        path: '/quotations/add',
        name: 'Quotation',
        permission: "add_crm_quotations",
        icon: FileCopy
    },
    {
        path: '/customers/add',
        name: 'Customer',
        permission: "add_crm_customers",
        icon: PeopleAltIcon
    },
    {
        path: '/manage/users/add',
        name: 'User',
        permission: "add_users",
        icon: GroupAddIcon
    },
];
export default QuickLink;

