//Import Components for Manage Routes
import Dashboard from "app/wsa/dashboard/views/dashboard";
import Calculator from "app/wsa/calculator/views/Calculator";
import ClientPackageList from "app/wsa/package/views/ClientPackageList";
import ViewPackage from "app/wsa/package/views/ViewPackage";
import PackageInvoice from "./package/views/PackageInvoice";
import AddressList from "app/wsa/address/views/AddressList";
import OneAddress from "app/wsa/address/views/OneAddress";
import Profile from "./user/views/Profile";

//Import icons for Manage Nav Section
import DashboardIcon from "@material-ui/icons/Dashboard";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import RoomIcon from "@material-ui/icons/Room";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import TransactionList from "./transaction/views/TransactionList";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

const wsaroutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    title: "Dashboard",
    id: "dashboard",
    icon: DashboardIcon,
    permission: [],
    child: [
      {
        path: "/dashboard",
        name: "Dashboard",
        title: "Main Dashboard",
        icon: DashboardIcon,
        component: Dashboard,
      },
      {
        path: "/client/addresses/list",
        name: "My Addresses",
        title: "My Addresses",
        icon: RoomIcon,
        component: AddressList,
        permission: "list.own.crm.customer.addresses",
      },
      /* {
        collapse: true,
        name: "My Addresses",
        icon: RoomIcon,
        state: "Address",
        permission: [
          "list.own.crm.customer.addresses",
          "add.crm.customer.addresses",
        ],
        subchild: [
          {
            path: "/client/addresses/list",
            title: "My Addresses",
            name: "All Addresses",
            component: AddressList,
            permission: "list.own.crm.customer.addresses",
          },
          {
            path: "/client/addresses/add",
            title: "Add New Address",
            name: "Add New Address",
            permission: "add.crm.customer.addresses",
          },
        ],
      }, */
      {
        collapse: true,
        name: "Packages",
        icon: MarkunreadMailboxIcon,
        state: "Packages",
        permission: ["list.own.wsa.packages"],
        subchild: [
          {
            path: "/client/packages/list/all",
            title: "All Packages",
            name: "All Packages",
            permission: "list.own.wsa.packages",
          },
          {
            path: "/client/packages/list/received",
            title: "Packages in Account",
            name: "Packages in Account",
            permission: "list.own.wsa.packages",
          },
          {
            path: "/client/packages/list/ready-to-mailout",
            title: "Packages in Account",
            name: "Ready for Mailout",
            permission: "list.own.wsa.packages",
          },
          /* {
            path: "/client/packages/list/on-the-way",
            title: "Packages in Account",
            name: "On the Way",
            permission: "list.own.wsa.packages",
          }, */
          {
            path: "/client/packages/list/hold",
            title: "Packages in Account",
            name: "Hold Packages",
            permission: "list.own.wsa.packages",
          },
          /* {
            path: "/client/packages/list/action-required",
            title: "Packages in Account",
            name: "Action Required",
            permission: "list.own.wsa.packages",
          }, */
          {
            path: "/client/packages/list/delivered",
            title: "Packages in Account",
            name: "Delivered",
            permission: "list.own.wsa.packages",
          },
        ],
      },
      {
        path: "/client/transactions",
        name: "My Transactions",
        title: "My Transactions",
        icon: MonetizationOnIcon,
        component: TransactionList,
      },
      {
        path: "/costcalculator",
        name: "Cost Calculator",
        title: "Cost Calculator",
        icon: LocalShippingIcon,
        component: Calculator,
      },
    ],
  },
  {
    path: "/client/packages/invoice/:id",
    name: "Package Invoice",
    component: PackageInvoice,
    routeonly: true,
  },
  {
    path: "/client/packages/:id",
    name: "View Package",
    component: ViewPackage,
    routeonly: true,
  },
  {
    path: "/client/packages/list/:status",
    name: "",
    title: "",
    component: ClientPackageList,
    routeonly: true,
  },
  {
    path: "/client/addresses/:id",
    name: "View Package",
    component: OneAddress,
    routeonly: true,
  },
  {
    path: "/client/profile",
    name: "Your Profile",
    title: "Your Profile",
    component: Profile,
    routeonly: true,
  },
];

export default wsaroutes;
