import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { deleteAddresses } from "../slices/addressSlice";
import { connect } from "react-redux";
import { useDispatch } from "redux/store";
import {
  CustomModal,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "@mycentyr/centyr-ui";
import { history } from "helpers/history";
import OneAddress from "./OneAddress";

const AddressActionButtons = (props) => {
  const dispatch = useDispatch();
  const {
    setPage,
    setSelected,
    page,
    rowsPerPage,
    keyword,
    row,
    rowLength,
  } = props;

  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);

  const [modal1, setModal1] = React.useState(false);
  const toggle1 = () => setModal1(!modal1);

  const [popover, setPopover] = React.useState(false);
  const togglePopover = () => setPopover(!popover);

  const onDelete = () => {
    toggle();
    if (rowLength === 1 && page !== 0) {
      setPage(page - 1);
      dispatch(
        deleteAddresses(
          props.authUserData.id,
          { id: row.id },
          page,
          rowsPerPage,
          keyword
        )
      );
    } else
      dispatch(
        deleteAddresses(
          props.authUserData.id,
          { id: row.id },
          page + 1,
          rowsPerPage,
          keyword
        )
      );
    setSelected([]);
  };

  return (
    <div className="text-right">
      {props.authUserData.permissions &&
        props.authUserData.permissions.includes(
          "edit.crm.customer.addresses"
        ) && (
          <Button
            color="primary"
            outline
            onClick={toggle1}
          >
            <EditIcon /> Edit
          </Button>
        )}{" "}
      {props.authUserData.permissions &&
        props.authUserData.permissions.includes(
          "delete.crm.customer.addresses"
        ) && (
          <Button
            color="danger"
            outline
            onClick={(e) => {
              e.stopPropagation();
              toggle();
            }}
          >
            <DeleteIcon /> Delete
          </Button>
        )}
      <CustomModal
        onModalAccept={onDelete}
        onModalCancel={toggle}
        modal={modal}
        toggle={toggle}
        modalTitle="Delete"
        modalBody="Are you sure you want to delete?"
      />
      <Modal isOpen={modal1} toggle={toggle1}>
        <ModalHeader toggle={toggle1}>Update Address</ModalHeader>
        <ModalBody>
          <OneAddress id={row.id} toggle={toggle1} />
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUserData: state.authUser.userData,
  };
};

export default connect(mapStateToProps, null)(AddressActionButtons);
