import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'redux/store';
import {
  fetchAddress,
  addAddress,
  updateAddress,
} from '../slices/addressSlice';
import { fetchAllCountries } from 'app/wsa/address/slices/countrySlice';

import {
  Row,
  Col,
  CustomSpinner,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from '@mycentyr/centyr-ui';

const schema = yup.object().shape({
  customer_address_name: yup.string().required('Name is required'),
  customer_address_1: yup.string().required('Address Line 1 is required'),
  customer_address_2: yup.string().required('Address Line 2 is required'),
  customer_address_country: yup.string().required('Country is required'),
});

const initialValue = {
  customer_id: 0,
  customer_address_name: '',
  customer_address_1: '',
  customer_address_2: '',
  customer_address_city: '',
  customer_address_state: '',
  customer_address_country: '',
  is_default: 0,
};

const OneAddress = (props) => {
  const { id, toggle } = props;

  const dispatch = useDispatch();
  const { addressData, isFetchAddressPending, isAddUpdateAddressPending } =
    useSelector((state) => state.address);

  const { countries } = useSelector((state) => state.country);

  const { userData } = useSelector((state) => state.authUser);

  useEffect(() => {
    dispatch(fetchAllCountries());
    if (id !== 'add') dispatch(fetchAddress(id));
  }, [id]);

  useEffect(() => {
    reset(
      id === 'add'
        ? initialValue
        : {
            customer_id: addressData.customer_id || 0,
            customer_address_name: addressData.customer_address_name || '',
            customer_address_1: addressData.customer_address_1 || '',
            customer_address_2: addressData.customer_address_2 || '',
            customer_address_city: addressData.customer_address_city || '',
            customer_address_state: addressData.customer_address_state || '',
            customer_address_pincode:
              addressData.customer_address_pincode || '',
            customer_address_country:
              addressData.customer_address_country || '',
            is_default: addressData.is_default || 0,
          }
    );
  }, [addressData, id]);

  const { register, handleSubmit, errors, reset, control } = useForm({
    mode: 'onblur',
    resolver: yupResolver(schema),
    defaultValues: initialValue,
  });

  const onSubmit = (data) => {
    const postData = {
      customer_id: userData.id,
      customer_address_name: data.customer_address_name,
      customer_address_1: data.customer_address_1,
      customer_address_2: data.customer_address_2,
      customer_address_city: data.customer_address_city,
      customer_address_state: data.customer_address_state,
      customer_address_pincode: data.customer_address_pincode,
      customer_address_country: data.customer_address_country,
      is_default: data.is_default || 0,
    };
    id === 'add'
      ? dispatch(addAddress(postData, toggle))
      : dispatch(updateAddress(id, postData, toggle));
  };

  if (isFetchAddressPending && !isAddUpdateAddressPending)
    return <CustomSpinner />;
  else
    return (
      <React.Fragment>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col lg='12'>
              <TextField
                className='mb-3'
                name='customer_address_name'
                label='Address Name'
                id='customer_address_name'
                variant='outlined'
                size='small'
                fullWidth
                InputLabelProps={id !== 'add' ? { shrink: true } : {}}
                error={errors.customer_address_name?.message ? true : false}
                helperText={errors.customer_address_name?.message}
                inputRef={register}
              />
            </Col>
            <Col lg='12'>
              <TextField
                className='mb-3'
                name='customer_address_1'
                label='Address Line 1'
                id='customer_address_1'
                variant='outlined'
                size='small'
                fullWidth
                InputLabelProps={id !== 'add' ? { shrink: true } : {}}
                error={errors.customer_address_1?.message ? true : false}
                helperText={errors.customer_address_1?.message}
                inputRef={register}
              />
            </Col>
            <Col lg='12'>
              <TextField
                className='mb-3'
                name='customer_address_2'
                label='Address Line 2'
                id='customer_address_2'
                variant='outlined'
                size='small'
                fullWidth
                InputLabelProps={id !== 'add' ? { shrink: true } : {}}
                error={errors.customer_address_2?.message ? true : false}
                helperText={errors.customer_address_2?.message}
                inputRef={register}
              />
            </Col>
            <Col lg='12'>
              <TextField
                className='mb-3'
                name='customer_address_city'
                label='City'
                id='customer_address_city'
                variant='outlined'
                size='small'
                fullWidth
                InputLabelProps={id !== 'add' ? { shrink: true } : {}}
                error={errors.customer_address_city?.message ? true : false}
                helperText={errors.customer_address_city?.message}
                inputRef={register}
              />
            </Col>
            <Col lg='12'>
              <TextField
                className='mb-3'
                name='customer_address_state'
                label='State'
                id='customer_address_state'
                variant='outlined'
                size='small'
                fullWidth
                InputLabelProps={id !== 'add' ? { shrink: true } : {}}
                error={errors.customer_address_state?.message ? true : false}
                helperText={errors.customer_address_state?.message}
                inputRef={register}
              />
            </Col>
            <Col lg='12'>
              <TextField
                className='mb-3'
                name='customer_address_pincode'
                label='Pin Code'
                id='customer_address_pincode'
                variant='outlined'
                size='small'
                fullWidth
                InputLabelProps={id !== 'add' ? { shrink: true } : {}}
                error={errors.customer_address_pincode?.message ? true : false}
                helperText={errors.customer_address_pincode?.message}
                inputRef={register}
              />
            </Col>
            <Col lg='12'>
              <FormControl
                variant='outlined'
                size='small'
                fullWidth
                error={!!errors.customer_address_country}
                className='mb-3'
              >
                <InputLabel>Country</InputLabel>
                <Controller
                  as={
                    <Select native label='Country'>
                      <option aria-label='None' value='' />
                      {countries?.map((country, index) => (
                        <option key={index} value={country.id}>
                          {country.country_name}
                        </option>
                      ))}
                    </Select>
                  }
                  name='customer_address_country'
                  control={control}
                />
                <FormHelperText>
                  {errors.customer_address_country?.message}
                </FormHelperText>
              </FormControl>
            </Col>
            <Col lg='12'>
              <FormControlLabel
                className='mb-3'
                control={
                  <Checkbox
                    name='is_default'
                    id='is_default'
                    color='primary'
                    value={1}
                    inputRef={register}
                    defaultChecked={addressData.is_default == 1}
                  />
                }
                label='Check to Set Default Address'
              />
            </Col>
          </Row>

          <Row>
            <Col lg='12' className='text-right'>
              <Button
                type='submit'
                color='primary'
                disabled={isAddUpdateAddressPending}
              >
                {id === 'add' ? 'Create' : 'Update'}
              </Button>
              <Button
                className='ml-2'
                type='button'
                color='secondary'
                onClick={toggle}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </form>
      </React.Fragment>
    );
};

export default OneAddress;
