import React from 'react';
import ReactDOM from 'react-dom';
import scriptLoader from 'react-async-script-loader';
import { CustomSpinner } from '@mycentyr/centyr-ui';

const CLIENT = {
  sandbox:
    'AYqWfOTGgBFnrmlyLNHIT5pOOv5Qp9uV55GedTOecTUcoPHaXsdoGH9KYUQ9xP9ENMPdB1-LuKCMbz6S',
  production:
    'AavW0uxBERyxlGXUaImSeXFFQ5YTSTk2bCi5JZCZPjSYkLvqGTZEVNvn8Xx9bTHndiapRtn5tkf3yVzq',
};

const CLIENT_ID =
  process.env.NODE_ENV === 'production' ? CLIENT.production : CLIENT.sandbox;

let PayPalButton = null;
class PaypalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showButtons: false,
      loading: true,
      paid: false,
    };

    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM });
      this.setState({ loading: false, showButtons: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    const scriptJustLoaded =
      !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PayPalButton = window.paypal.Buttons.driver('react', {
          React,
          ReactDOM,
        });
        this.setState({ loading: false, showButtons: true });
      }
    }
  }
  createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: this.props.package.package_id,
          amount: {
            currency_code: 'USD',
            value: this.props.rate.rate,
          },
        },
      ],
    });
  };

  onApprove = (data, actions) => {
    actions.order
      .capture()
      .then((details) => {
        // console.table(data);
        // return console.table(details);
        const paymentData = {
          payerID: data.payerID,
          orderID: data.orderID,
        };
        this.props.onSuccess(paymentData, details, 'paypal');
        this.setState({ showButtons: false, paid: true });
      })
      .catch((error) => console.log(error));
  };

  render() {
    const { showButtons, paid, loading } = this.state;

    return (
      <div className='main'>
        {loading && <CustomSpinner />}
        {showButtons && (
          <div>
            <PayPalButton
              style={{ layout: 'horizontal', color: 'white' }}
              createOrder={(data, actions) => this.createOrder(data, actions)}
              onApprove={(data, actions) => this.onApprove(data, actions)}
            />
          </div>
        )}
        {paid && <CustomSpinner />}
      </div>
    );
  }
}

export default scriptLoader(
  `https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}`
)(PaypalButton);
