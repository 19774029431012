import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { history } from 'helpers/history';

import logoIcon from 'assets/images/WeShip1.png';
import img2 from 'assets/images/background/plain.png';

import { login, sendVerificationEmail } from '../actions/authActions';

import LockIcon from '@material-ui/icons/Lock';

import { appName } from 'utils/axios';

import {
  Row,
  Col,
  TextField,
  CustomInput,
  Button,
  CustomSpinner,
} from '@mycentyr/centyr-ui';

const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
};
const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Please enter valid email'),
  password: yup.string().required('Password is required'),
});

const Login = (props) => {
  const [email, setEmail] = useState(null);

  document.title = 'Sign In ' + appName;

  const { register, handleSubmit, errors } = useForm({
    mode: 'onblur',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const postData = {
      email: data.email,
      password: data.password,
    };
    setEmail(data.email);
    props.loginUser(
      postData,
      (props.location.state && props.location.state.from.pathname) || '/'
    );
  };

  return (
    <div className=''>
      {/*--------------------------------------------------------------------------------*/}
      {/*Login Cards*/}
      {/*--------------------------------------------------------------------------------*/}
      <div
        className='auth-wrapper d-flex no-block justify-content-center align-items-center'
        style={sidebarBackground}
      >
        <div className='auth-box on-sidebar'>
          <div id='loginform'>
            <div className='logo'>
              <a
                href='https://weshipafrica.com/'
                target='_blank'
                className='db'
              >
                <img
                  src={logoIcon}
                  height={35}
                  className='authLogo'
                  alt='logo'
                />
              </a>
              <h5 className='font-medium mb-3'>Sign In to Dashboard</h5>
            </div>
            <Row>
              <Col xs='12'>
                {props.isLoginPending && <CustomSpinner />}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    className='mb-3'
                    label='Email Address'
                    name='email'
                    id='email'
                    variant='outlined'
                    size='small'
                    fullWidth
                    error={errors.email?.message ? true : false}
                    helperText={errors.email?.message}
                    inputRef={register}
                  />
                  <TextField
                    inputRef={register}
                    className='mb-3'
                    label='Password'
                    name='password'
                    type='password'
                    id='password'
                    variant='outlined'
                    size='small'
                    fullWidth
                    autoComplete='off'
                    error={errors.password?.message ? true : false}
                    helperText={errors.password?.message}
                  />
                  <div className='d-flex no-block align-items-center mb-3'>
                    <CustomInput
                      type='checkbox'
                      id='exampleCustomCheckbox'
                      label='Remember Me'
                    />
                    <div className='ml-auto'>
                      <a
                        role='button'
                        id='to-recover'
                        onClick={() => history.push('/forgot/password')}
                        className='forgot text-dark float-right'
                      >
                        <LockIcon fontSize='small' /> Forgot Password?
                      </a>
                    </div>
                  </div>
                  <Row className='mb-3'>
                    <Col xs='12'>
                      <Button
                        type='submit'
                        color='primary'
                        block
                        disabled={props.isLoginPending}
                      >
                        Login
                      </Button>
                    </Col>
                  </Row>
                  <Row className='mb-3 text-center'>
                    <Col xs='12'>
                      <Button
                        type='button'
                        color='primary'
                        block
                        outline
                        onClick={() => history.push('/sign/up')}
                      >
                        Create New Account
                      </Button>
                      <p>By continuing, you agree to our Terms of Service.</p>
                    </Col>
                  </Row>
                  {props.loginError && (
                    <React.Fragment>
                      <div className={'alert alert-danger'}>
                        {props.loginError}
                      </div>
                      {props.isEmailNotVerified && (
                        <Button
                          type='button'
                          color='primary'
                          block
                          outline
                          onClick={() => props.sendVerificationEmail(email)}
                        >
                          Resend Verification Email
                        </Button>
                      )}
                    </React.Fragment>
                  )}
                </form>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isEmailNotVerified: state.auth.isEmailNotVerified,
    isLoginPending: state.auth.isLoginPending,
    loginError: state.auth.loginError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (postData, from) => {
      dispatch(login(postData, from));
    },
    sendVerificationEmail: (email) => {
      dispatch(sendVerificationEmail(email));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
