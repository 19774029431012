import React from "react";
import { connect } from "react-redux";
import { useDispatch } from "redux/store";
import { CustomModal, Button } from "@mycentyr/centyr-ui";
import { history } from "helpers/history";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import VisibilityIcon from "@material-ui/icons/Visibility";

const PackageActionButtons = (props) => {
  const dispatch = useDispatch();
  const {
    setPage,
    setSelected,
    page,
    rowsPerPage,
    keyword,
    row,
    rowLength,
  } = props;

  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);

  const [popover, setPopover] = React.useState(false);
  const togglePopover = () => setPopover(!popover);

  const onDelete = () => {
    toggle();
    if (rowLength === 1 && page !== 0) {
      setPage(page - 1);
      dispatch(deletePackages({ id: row.id }, page, rowsPerPage, keyword));
    } else
      dispatch(deletePackages({ id: row.id }, page + 1, rowsPerPage, keyword));
    setSelected([]);
  };

  return (
    <div className="text-right">
      {props.authUserData.permissions &&
        props.authUserData.permissions.includes("view.wsa.packages") && (
          <React.Fragment>
            <Button
              color="primary"
              outline
              onClick={(e) => {
                e.stopPropagation();
                history.push(`packages/invoice/${row.id}`);
              }}
            >
              <PictureAsPdfIcon /> Invoice
            </Button>
          </React.Fragment>
        )}
      <CustomModal
        onModalAccept={onDelete}
        onModalCancel={toggle}
        modal={modal}
        toggle={toggle}
        modalTitle="Delete"
        modalBody="Are you sure you want to delete?"
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUserData: state.authUser.userData,
  };
};

export default connect(mapStateToProps, null)(PackageActionButtons);
