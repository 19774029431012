import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { history } from 'helpers/history';

import logoIcon from 'assets/images/WeShip1.png';
import img2 from 'assets/images/background/plain.png';

import { register } from '../actions/authActions';
import { appName } from 'utils/axios';

import {
  Row,
  Col,
  TextField,
  Button,
  CustomSpinner,
} from '@mycentyr/centyr-ui';

const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
};
//eslint-disable-next-line
const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
const schema = yup.object().shape({
  user_firstname: yup.string().required('First Name is required'),
  user_lastname: yup.string().required('Last Name is required'),
  user_phone_number: yup
    .string()
    .matches(phoneRegExp, 'Phone number is invalid')
    .required('Phone number is required'),
  user_email: yup
    .string()
    .email('Email is invalid')
    .required('Email is required'),
  user_password: yup
    .string()
    .required('Password is required')
    .oneOf(
      [yup.ref('confirm_password'), null],
      'Password and Confirm Password does not match'
    )
    .min(6, 'Password must be Atleast 6 Charecters'),
});

const Register = (props) => {
  document.title = 'Sign Up ' + appName;

  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onblur',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const postData = {
      user_firstname: data.user_firstname,
      user_lastname: data.user_lastname,
      user_email: data.user_email,
      user_phone_number: data.user_phone_number,
      user_password: data.user_password,
    };
    props.registerUser(postData, '/');
  };

  return (
    <div className=''>
      {/*--------------------------------------------------------------------------------*/}
      {/*Register Cards*/}
      {/*--------------------------------------------------------------------------------*/}
      <div
        className='auth-wrapper d-flex no-block justify-content-center align-items-center'
        style={sidebarBackground}
      >
        <div className='auth-box on-sidebar'>
          <div id='registerform'>
            {props.registerError && (
              <div className={'alert alert-danger'}>{props.registerError}</div>
            )}
            <div className='logo'>
              <a
                href='https://weshipafrica.com/'
                target='_blank'
                className='db'
              >
                <img
                  src={logoIcon}
                  height={35}
                  className='authLogo'
                  alt='logo'
                />
              </a>
              <h5 className='font-medium mb-3'>Sign Up to Dashboard</h5>
            </div>
            <Row>
              <Col xs='12'>
                {props.isRegisterPending && <CustomSpinner />}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    label='First Name *'
                    className='mb-3'
                    name='user_firstname'
                    id='user_firstname'
                    variant='outlined'
                    size='small'
                    fullWidth
                    error={errors.user_firstname?.message ? true : false}
                    helperText={errors.user_firstname?.message}
                    inputRef={register}
                  />
                  <TextField
                    label='Last Name *'
                    className='mb-3'
                    name='user_lastname'
                    id='user_lastname'
                    variant='outlined'
                    size='small'
                    fullWidth
                    error={errors.user_lastname?.message ? true : false}
                    helperText={errors.user_lastname?.message}
                    inputRef={register}
                  />
                  <TextField
                    label='Phone Number *'
                    className='mb-3'
                    name='user_phone_number'
                    id='user_phone_number'
                    variant='outlined'
                    size='small'
                    fullWidth
                    error={errors.user_phone_number?.message ? true : false}
                    helperText={errors.user_phone_number?.message}
                    inputRef={register}
                  />
                  <TextField
                    label='Email Address *'
                    className='mb-3'
                    name='user_email'
                    id='user_email'
                    variant='outlined'
                    size='small'
                    fullWidth
                    error={errors.user_email?.message ? true : false}
                    helperText={errors.user_email?.message}
                    inputRef={register}
                  />
                  <TextField
                    label='Password'
                    inputRef={register}
                    className='mb-3'
                    name='user_password'
                    type='password'
                    id='user_password'
                    variant='outlined'
                    size='small'
                    fullWidth
                    autoComplete='off'
                    error={errors.user_password?.message ? true : false}
                    helperText={errors.user_password?.message}
                  />
                  <p>
                    <strong>
                      Enter Minimum 6 to 14 Alpha Numeric Characters.
                      <br />
                      Minimum (1 Upper case, 1 Lower case, 1 Symbol ,1 Number)
                    </strong>
                  </p>
                  <TextField
                    label='Confirm Password'
                    inputRef={register}
                    className='mb-3'
                    name='confirm_password'
                    type='password'
                    id='confirm_password'
                    variant='outlined'
                    size='small'
                    fullWidth
                    autoComplete='off'
                  />
                  <Row className='mb-3'>
                    <Col xs='12'>
                      <Button
                        type='submit'
                        color='primary'
                        block
                        disabled={props.isRegisterPending}
                      >
                        Register
                      </Button>
                    </Col>
                  </Row>
                  <Row className='mb-3 text-center'>
                    <Col xs='12'>
                      <Button
                        type='button'
                        color='primary'
                        block
                        outline
                        onClick={() => history.push('/sign/in')}
                      >
                        Sign In
                      </Button>
                      <p>By continuing, you agree to our Terms of Service.</p>
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isRegisterPending: state.auth.isRegisterPending,
    registerError: state.auth.registerError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: (postData, from) => {
      dispatch(register(postData, from));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
