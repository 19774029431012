import Login from "app/auth/views/login";
import Register from "app/auth/views/register";
import ResetPasswordForm from "app/auth/views/ResetPasswordForm";
import ChangePasswordForm from "app/auth/views/ChangePasswordForm";

var authRoutes = [
  {
    path: "/forgot/password",
    name: "Reset Password",
    icon: "mdi mdi-account-key",
    component: ResetPasswordForm,
  },
  {
    path: "/change/password",
    name: "Reset Password",
    icon: "mdi mdi-account-key",
    component: ChangePasswordForm,
  },
  {
    path: "/sign/in",
    name: "Login",
    icon: "mdi mdi-account-key",
    component: Login,
  },
  {
    path: "/sign/up",
    name: "Login",
    icon: "mdi mdi-account-key",
    component: Register,
  },
];
export default authRoutes;
