import React from 'react';
import { Card, CardTitle, CardBody, Row, Col } from '@mycentyr/centyr-ui';

import { connect } from 'react-redux';

class Dashboard extends React.Component {
  render() {
    return (
      <div>
        <div className='page-header'>
          <Row>
            <Col className='page-title'>
              <h1>{this.props.title}</h1>
            </Col>
          </Row>
        </div>

        <div className='content-wrapper'>
          <Row>
            <Col lg={6}>
              <Card>
                <CardTitle>How to Ship</CardTitle>
                <CardBody>
                  {this.props.authUserData && (
                    <React.Fragment>
                      <p className='font-weight-bold'>WeShipAfrica</p>
                      <p className='font-weight-bold'>
                        {this.props.authUserData.user_display_name}
                      </p>
                      <p className='font-weight-bold'>
                        {this.props.authUserData.user_username}
                      </p>
                      <p className='font-weight-bold'>4201 TONNELLE AVE</p>
                      <p className='font-weight-bold'>SUITE 1</p>
                      <p className='font-weight-bold'>NORTH BERGEN, NJ 07047</p>
                      <p className='font-weight-bold'>PH # 973-979-5063</p>
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUserData: state.authUser.userData,
  };
};

export default connect(mapStateToProps, null)(Dashboard);
