import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "redux/store";
import { fetchPackages, consolidatePackage } from "../slices/packageSlice";
import PackageSkeleeton from "./PackageSkeleeton";

import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";

import MuiTableCheckbox from "layouts/parts/mui-table-checkbox";

import { Row, Col, Button } from "@mycentyr/centyr-ui";

const ConsolidatePackage = (props) => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);

  const { packages, noOfRecords, isFetchPackagesPending } = useSelector(
    (state) => state.package
  );

  const headCells = [
    {
      id: "package_id",
      numeric: false,
      disablePadding: false,
      label: "Package ID",
    },
    {
      id: "package_type",
      numeric: false,
      disablePadding: false,
      label: "Package Type",
    },
    {
      id: "package_inward_tracking_number",
      numeric: false,
      disablePadding: false,
      label: "Inward Tracking Number",
    },
    {
      id: "package_status",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
  ];

  const createData = (
    id,
    package_id,
    package_type,
    package_inward_tracking_number,
    package_status
  ) => {
    return {
      id,
      package_id,
      package_type,
      package_inward_tracking_number,
      package_status,
    };
  };

  const onReload = (rowsPerPage, keyword) => {
    dispatch(fetchPackages(1, rowsPerPage, keyword, "consolidate"));
  };

  useEffect(() => {
    let rowsLcl = [];
    packages.map((pkg) =>
      rowsLcl.push(
        createData(
          pkg.id,
          pkg.package_id,
          pkg.package_type,
          pkg.package_inward_tracking_number,
          pkg.package_status
        )
      )
    );
    setRows(rowsLcl);
  }, [packages]);

  useEffect(() => {
    dispatch(fetchPackages(1, 10, "", "consolidate"));
  }, []);

  return (
    <div>
      <MuiTableCheckbox
        headCells={headCells}
        rows={rows}
        onReload={onReload} // comment this to disable refresh option
        isFetchPending={isFetchPackagesPending}
        // chooseColumn={false}   //=>to false display column option
        noOfRecords={noOfRecords}
        MassActions={MassAction}
        // handlePaginationChange={handlePaginationChange}
        // onSearch={onSearch}
        CustomSkeleton={PackageSkeleeton}
        otherProps={{ toggle: props.toggle }}
      />

      {/* <Row>
        <Col lg={12} className="text-center">
          <Button color="primary" outline size="lg">
            Consolidate
          </Button>
        </Col>
      </Row> */}
    </div>
  );
};

export default ConsolidatePackage;

const MassAction = (props) => {
  const dispatch = useDispatch();
  return (
    <a
      role="button"
      className="anchoreless-button text-primary"
      style={props.selected < 2 ? { pointerEvents: "none" } : {}}
      onClick={(e) => {
        e.stopPropagation();
        dispatch(
          consolidatePackage({ id: props.selected }, props.otherProps.toggle)
        );
        props.setSelected([]);
      }}
    >
      <CollectionsBookmarkIcon /> Consolidate
    </a>
  );
};
