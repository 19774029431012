import { createSlice } from "@reduxjs/toolkit";
import { instance } from "utils/axios";
import { CustomSnackbar } from "@mycentyr/centyr-ui";

const initialState = {
  countries: [],
  noOfRecords: 0,
  isFetchCountriesPending: false,
};

const slice = createSlice({
  name: "countryData",
  initialState,
  reducers: {
    fetchCountries(state, action) {
      state.countries = action.payload.items;
      state.noOfRecords = action.payload.count;
    },
    setFetchCountriesPending(state, action) {
      state.isFetchCountriesPending = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const fetchAllCountries = (id) => async (dispatch) => {
  dispatch(slice.actions.setFetchCountriesPending(true));
  dispatch(slice.actions.fetchCountries({ items: [], count: -1 }));

  const response = await instance
    .post(`countries/get`, {})
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setFetchCountriesPending(false));
  if (response) {
    dispatch(
      slice.actions.fetchCountries({
        items: response.data.data,
        count: response.data.data.length,
      })
    );
  }
};

export default slice;
