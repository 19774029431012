import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'redux/store';
import { fetchPackages, deletePackages } from '../slices/packageSlice';
import PackageActionButtons from './PackageActionButtons';
import { history } from 'helpers/history';
import PackageSkeleeton from './PackageSkeleeton';
import MuiTable from 'layouts/parts/mui-table';

import { appName } from 'utils/axios';
import { Row, Col, Button } from '@mycentyr/centyr-ui';

const ClientPackageList = (props) => {
  const { status } = props.match.params;

  let title = '';
  if (status == 'all') {
    title = 'All Packages';
  } else if (status == 'received') {
    title = 'All Packages';
  } else if (status == 'ready-to-mailout') {
    title = 'Ready for Mailout Packages';
  } else if (status == 'on-the-way') {
    title = 'On the Way Packages';
  } else if (status == 'hold') {
    title = 'Hold Packages';
  } else if (status == 'action-required') {
    title = 'Action Required';
  } else if (status == 'delivered') {
    title = 'Delivered Packages';
  }

  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [rows, setRows] = useState([]);

  const { packages, noOfRecords, isFetchPackagesPending } = useSelector(
    (state) => state.package
  );

  const headCells = [
    {
      id: 'package_id',
      numeric: false,
      disablePadding: false,
      label: 'Package ID',
    },
    {
      id: 'package_type',
      numeric: false,
      disablePadding: false,
      label: 'Package Type',
    },
    {
      id: 'package_outward_shipping_carrier',
      numeric: false,
      disablePadding: false,
      label: 'Outward Carrier',
    },
    {
      id: 'package_outward_tracking_number',
      numeric: false,
      disablePadding: false,
      label: 'Outward Tracking Number',
    },
    {
      id: 'package_status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
    { id: 'action', numeric: true, disablePadding: false, label: 'Action' },
  ];

  const createData = (
    id,
    package_id,
    package_type,
    package_outward_shipping_carrier,
    package_outward_tracking_number,
    package_status
  ) => {
    return {
      id,
      package_id,
      package_type,
      package_outward_shipping_carrier,
      package_outward_tracking_number,
      package_status,
    };
  };

  const onReload = (rowsPerPage, keyword) => {
    dispatch(fetchPackages(1, rowsPerPage, keyword, '', status));
  };

  const handlePaginationChange = (pageNo, perPage, keyword) => {
    dispatch(fetchPackages(pageNo, perPage, keyword, '', status));
  };

  const onMultiDelete = (postData, pageNo, perPage, keyword) => {
    dispatch(deletePackages(postData, pageNo, perPage, keyword, '', status));
  };

  const onSearch = (keyword, perPage) => {
    if (keyword.length > 2 || keyword.length === 0)
      dispatch(fetchPackages(1, perPage, keyword, '', status));
  };

  useEffect(() => {
    let rowsLcl = [];
    packages.map((pkg) =>
      rowsLcl.push(
        createData(
          pkg.id,
          pkg.package_id,
          pkg.package_type,
          pkg.package_outward_shipping_carrier,
          pkg.package_outward_tracking_number,
          pkg.package_status
        )
      )
    );
    setRows(rowsLcl);
  }, [packages]);

  useEffect(() => {
    document.title = title + appName;
    dispatch(fetchPackages(1, 10, '', '', status));
  }, [status]);

  return (
    <div>
      <div className='page-header'>
        <Row>
          <Col className='page-title'>
            <h1>{title}</h1>
          </Col>
          <Col className='text-right page-header-button'></Col>
        </Row>
      </div>
      <div className='content-wrapper'>
        <MuiTable
          headCells={headCells}
          rows={rows}
          onReload={onReload} // comment this to disable refresh option
          isFetchPending={isFetchPackagesPending}
          // chooseColumn={false}   //=>to false display column option
          noOfRecords={noOfRecords}
          handlePaginationChange={handlePaginationChange}
          multipleDelete={
            props.authUserData.permissions &&
            props.authUserData.permissions.includes('delete.wsa.packages')
              ? true
              : false
          }
          onMultiDelete={onMultiDelete}
          onSearch={onSearch}
          CustomSkeleton={PackageSkeleeton}
          Actions={PackageActionButtons}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUserData: state.authUser.userData,
  };
};

export default connect(mapStateToProps, null)(ClientPackageList);
